import React, { useState, useEffect } from "react";
import "./TabSelectionPage.css"; // Make sure to create and link a corresponding CSS file
import mobileBox from "../../assets/mobile-in-box.png";
import charger from "../../assets/charger.png";
import adapter from "../../assets/adapter.png";
import smallTab from "../../assets/allTabs/pure-small-tab.png";
// import bigTab from "../../assets/allTabs/bigTab.png";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { BrowserView, MobileView } from "react-device-detect";
import universalFunctions from "../../Utils/universalFunctions";
import bigTab from "../../assets/pure-tab-with-logo.png";

// Sample images object (replace these paths with your actual images)
const imagesByColor = {
  color1: bigTab,
  color2: smallTab,
  color3: bigTab,
  color4: smallTab,
  color5: bigTab,
  color6: smallTab,
  // Add more colors and their corresponding images
};

function TabSelectionPage() {
  if (universalFunctions.isLocalStorageAvailable) {
    var savedState = JSON.parse(localStorage.getItem("tabSelectionState"));
  }
  const [currentImage, setCurrentImage] = useState(
    savedState ? savedState.currentImage : bigTab
  );
  const imageKeys = Object.keys(imagesByColor);
  const [currentIndex, setCurrentIndex] = useState(
    savedState ? savedState.currentIndex : 0
  );
  const [selectedColor, setSelectedColor] = useState(
    savedState ? savedState.selectedColor : "#dddddd"
  ); // Default selected color
  const [selectedKosherLevel, setSelectedKosherLevel] = useState(
    savedState ? savedState.selectedKosherLevel : ""
  );
  const [selectedBuyOption, setSelectedBuyOption] = useState(
    savedState ? savedState.selectedBuyOption : ""
  );
  const [selectedPrice, setSelectedPrice] = useState(
    savedState ? savedState.selectedPrice : ""
  );
  const navigate = useNavigate();
  const [deviceName, setdeviceName] = useState(
    savedState ? savedState.deviceName : "G10"
  ); // Default selected color
  const [devicePrice, setDevicePrice] = useState(
    savedState ? savedState.devicePrice : "250"
  ); // Default selected color
  const [SelectedPlanPrice, setSelectedPlanPrice] = useState(
    savedState ? savedState.selectedPrice : ""
  );
  const [selectMaxsipOrOther, setSelectMaxsipOrOther] = useState(
    savedState ? savedState.selectMaxsipOrOther : ""
  );
  const [visibilityState, setVisibilityState] = useState({
    paymentOption: false,
    planSelect: false,
    maxsipPlan: false,
    otherPlan: false,
  });

  const handleArrowClick = (direction) => {
    console.log(direction);
    let newIndex = currentIndex + (direction === "left" ? -1 : 1);
    if (newIndex < 0) newIndex = imageKeys.length - 1;
    if (newIndex >= imageKeys.length) newIndex = 0;
    setCurrentImage(imagesByColor[imageKeys[newIndex]]);
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
  }, []);

  useEffect(() => {
    if (universalFunctions.isLocalStorageAvailable) {
      if (savedState) {
        const savedState = localStorage.getItem("tabSelectionState");

        const parsedState = JSON.parse(savedState);
      }
      const state = {
        currentImage,
        currentIndex,
        selectedColor,
        selectedKosherLevel,
        selectedBuyOption,
        selectedPrice,
        deviceName,
        devicePrice,
      };
      localStorage.setItem("tabSelectionState", JSON.stringify(state));
      const savedState1 = localStorage.getItem("tabSelectionState");
      if (savedState) {
        const parsedState = JSON.parse(savedState1);
        console.log("2. Using state from localStorage:", parsedState);
      }
    }
  }, [
    currentImage,
    currentIndex,
    selectedColor,
    selectedKosherLevel,
    selectedBuyOption,
    selectedPrice,
    deviceName,
    devicePrice,
  ]);

  const changeColor = (color) => {
    setSelectedColor(color);

    if (color === "#fefeff") {
      setSelectedColor("white");
      setCurrentImage(imagesByColor.color1);
      setCurrentIndex(0);
    } else if (color === "#7796b8") {
      setSelectedColor("turq");

      setCurrentImage(imagesByColor.color2);
      setCurrentIndex(1);
    } else if (color === "#f7ddff") {
      setSelectedColor("pink");

      setCurrentImage(imagesByColor.color3);
      setCurrentIndex(2);
    } else if (color === "#6092b1") {
      setSelectedColor("navy");

      setCurrentImage(imagesByColor.color4);
      setCurrentIndex(3);
    } else if (color === "#6c6e81") {
      setSelectedColor("grey");

      setCurrentImage(imagesByColor.color5);
      setCurrentIndex(4);
    } else if (color === "#353432") {
      setSelectedColor("black");

      setCurrentImage(imagesByColor.color6);
      setCurrentIndex(5);
    }
  };

  const handleContinue = () => {
    if (
      // !selectedKosherLevel ||
      !selectedColor ||
      !selectedBuyOption ||
      !selectedPrice
    ) {
      Swal.fire({
        title: "Missing Selection",
        text: "Please make sure all selections are made before continuing.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else {
      console.log(`Tab Selected Kosher Level: ${selectedKosherLevel}`);
      console.log(`Tab Selected Color: ${selectedColor}`);
      console.log(`Tab Selected buy option: ${selectedBuyOption}`);
      console.log(`Tab Selected price: ${selectedPrice}`);
      console.log(`Tab Selected Devise: ${deviceName}`);
      if (universalFunctions.isLocalStorageAvailable) {
        localStorage.removeItem("checkOutState");
      }

      // Navigate only if all selections are made
      navigate("/checkout", {
        state: {
          selectedKosherLevel,
          selectedColor: "Black",
          selectedBuyOption,
          deviceName,
          SelectedPlanPrice,
          devicePrice,
        },
      });
    }
  };

  const selectedBuyOptions = (option, price) => {
    setVisibilityState((prevState) => ({
      ...prevState,
      paymentOption: true,
    }));
    setSelectedBuyOption(option);
    setDevicePrice(price);
  };

  const setDevicePriceFun = (price) => {
    setDevicePrice(price);
  };

  const selectPrice = (price) => {
    setVisibilityState((prevState) => ({
      ...prevState,
      planSelect: true,
    }));
    setSelectedPrice(price);
    setSelectedPlanPrice(price);
  };

  const setSelectedKosherLevelFun = (lvl) => {
    console.log(">>>>> " + lvl);

    setSelectedKosherLevel(lvl);
  };

  const setCurrentImageFunc = (currentImg) => {
    console.log(">>>>> " + currentImg);
    setCurrentImage(currentImg);

    if (currentImg === "smallTab") {
      setdeviceName("X8G");
      setCurrentImage(smallTab);
    } else if (currentImg === "bigTab") {
      setdeviceName("G10");
      setCurrentImage(bigTab);
    }
  };

  const maxsipOrOthers = (option) => {
    setSelectMaxsipOrOther(option);

    if (option === "maxsip") {
      setVisibilityState((prevState) => ({
        ...prevState,
        maxsipPlan: true,
      }));
      setVisibilityState((prevState) => ({
        ...prevState,
        otherPlan: false,
      }));
      setVisibilityState((prevState) => ({
        ...prevState,
        planSelect: false,
      }));
    } else {
      setVisibilityState((prevState) => ({
        ...prevState,
        otherPlan: true,
        maxsipPlan: false,
      }));
      setVisibilityState((prevState) => ({
        ...prevState,
        planSelect: true,
      }));
      setSelectedPlanPrice("$9.99");
      setSelectedPrice("$9.99");
    }
  };

  return (
    <div className="tab-selection-container">
      <LeftSide
        handleArrowClick={handleArrowClick}
        currentImage={currentImage}
        selectedBuyOptions={selectedBuyOptions}
        selectedBuyOption={selectedBuyOption}
        selectPrice={selectPrice}
        selectedPrice={selectedPrice}
        handleContinue={handleContinue}
        setCurrentImageFunc={setCurrentImageFunc}
        visibilityState={visibilityState}
        SelectedPlanPrice={SelectedPlanPrice}
        selectMaxsipOrOther={selectMaxsipOrOther}
        maxsipOrOthers={maxsipOrOthers}
        deviceName={deviceName}
      />
      <RightSide
        changeColor={changeColor}
        selectedColor={selectedColor}
        setSelectedKosherLevel={setSelectedKosherLevelFun}
        selectedKosherLevel={selectedKosherLevel}
        setCurrentImageFunc={setCurrentImageFunc}
        currentImage={currentImage}
        handleContinue={handleContinue}
        visibilityState={visibilityState}
        selectedBuyOption={selectedBuyOption}
        SelectedPlanPrice={SelectedPlanPrice}
        deviceName={deviceName}
      />
      <MobileView>
        <center className="in-box-text-tab">What's in the box</center>
        <center>
          <div className="what-in-box-tab">
            <div className="item">
              <img src={smallTab} alt="Tab & Case" />
              <p>Phone & Case</p>
            </div>
            <div className="item">
              <img src={charger} alt="Charger Cable" />
              <p>Charger Cable</p>
            </div>
            <div className="item">
              <img src={adapter} alt="Power Adapter" />
              <p>Power Adapter</p>
            </div>
          </div>
        </center>
      </MobileView>
    </div>
  );
}

const RightSide = ({
  changeColor,
  selectedColor,
  setSelectedKosherLevel,
  selectedKosherLevel,
  setCurrentImageFunc,
  currentImage,
  handleContinue,
  visibilityState,
  selectedBuyOption,
  SelectedPlanPrice,
  deviceName,
}) => {
  const kosherLevels = [
    "PURE +",
    "PURE",
    "Basic",
    "Pure Light",
    "Waze Only",
    "Zoom Only",
    "Business",
  ];
  const kosherLevelsDes = [
    "All Apps",
    "Fewer Entertainment Apps",
    "Basic Banking Apps",
    "Torah Apps",
    "",
    "",
    "All Business related apps",
  ];

  return (
    <div className="right-section-tab">
      <BrowserView>
        <div className="model-info-tab">
          <h2 className="model-title-tab">
            Model: <span className="info-icon"></span>
          </h2>
          <div
            className="model-selection"
            style={{ border: currentImage === bigTab ? "2px solid black" : "" }}
            onClick={() => setCurrentImageFunc("bigTab")}
          >
            <div className="model-details">
              <div className="model-name">G10 Tablet</div>
              <div className="model-size">10 inch display</div>
            </div>
            <div>
              {" "}
              <div className="promo-animation">Special promo</div>
              <div
                className="model-price"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                From $9.99 or{" "}
                <span style={{ textDecoration: "line-through", color: "red" }}>
                  $350
                </span>{" "}
                $99.99
              </div>{" "}
            </div>
          </div>

          <div
            className="model-selection"
            style={{
              border: currentImage === smallTab ? "2px solid black" : "",
            }}
            onClick={() => setCurrentImageFunc("smallTab")}
          >
            <div className="model-details">
              <div className="model-name">X8G Tablet</div>
              <div className="model-size">8 inch display</div>
            </div>
            <div>
              {" "}
              <div className="promo-animation">Special promo</div>
              <div
                className="model-price"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                From $9.99 or{" "}
                <span style={{ textDecoration: "line-through", color: "red" }}>
                  $350
                </span>{" "}
                $99.99
              </div>{" "}
            </div>
          </div>
        </div>
        {deviceName === "" ? (
          <></>
        ) : (
          <div className="review-menu">
            <center>
              <h2>REVIEW ORDER</h2>
            </center>
            <div className="review-row">
              <span className="review-label">Model:</span>
              <span className="review-value">{deviceName}</span>
            </div>

            {selectedBuyOption == "" ? (
              <></>
            ) : (
              <div className="review-row">
                <span className="review-label">Payment Plan:</span>
                <span className="review-value">
                  {selectedBuyOption === ""
                    ? ""
                    : selectedBuyOption === "option2"
                    ? "$9.99 per month for 12 months"
                    : selectedBuyOption === "option3"
                    ? "$9.99 per month for 12 months"
                    : "One time payment $350"}
                </span>
              </div>
            )}

            {SelectedPlanPrice == "" ? (
              <></>
            ) : (
              <div className="review-row">
                <span className="review-label">Data Plan:</span>
                <span className="review-value">
                  {SelectedPlanPrice === ""
                    ? ""
                    : SelectedPlanPrice === "$15"
                    ? "1 GB"
                    : SelectedPlanPrice === "$20"
                    ? "3 GB"
                    : SelectedPlanPrice === "$25"
                    ? "5 GB"
                    : SelectedPlanPrice === "$35"
                    ? "10 GB"
                    : "TMobile & At&t"}
                </span>
              </div>
            )}
          </div>
        )}
      </BrowserView>
      {/* <h2>Choose Kosher Level:</h2> */}
      {/* 
      <div className="choose-kosher-level-tab">
        {kosherLevels.map((level, index) => (
          <button
            style={{
              border: selectedKosherLevel === level ? "5px solid grey" : "",
            }}
            key={level}
            className={`kosher-level-button ${
              selectedKosherLevel === level ? "selected" : ""
            }`}
            onClick={() => setSelectedKosherLevel(level)}
          >
            {level}
            <span
              className={kosherLevelsDes[index] == "" ? "" : "tooltip-text"}
            >
              {kosherLevelsDes[index]}
            </span>
          </button>
        ))}
      </div> */}

      {visibilityState.planSelect && (
        <div
          className={visibilityState.planSelect ? "visible-options" : "hidden"}
        >
          <MobileView>
            {/* <div className="port-number-section-tab">
              <h2>Port your existing number</h2>
              <button
                onClick={() =>
                  universalFunctions.portYourExistingNumberFunction()
                }
                className="port-button-tab"
              >
                Port
              </button>
            </div> */}
            <div className="parent-continue-button">
              <button onClick={handleContinue} className="continue-button">
                Continue to buy
              </button>
            </div>
          </MobileView>
        </div>
      )}
    </div>
  );
};

const LeftSide = ({
  handleArrowClick,
  currentImage,
  selectedBuyOptions,
  selectedBuyOption,
  selectPrice,
  selectedPrice,
  handleContinue,
  setCurrentImageFunc,
  visibilityState,
  SelectedPlanPrice,
  selectMaxsipOrOther,
  maxsipOrOthers,
  deviceName,
}) => {
  return (
    <div className="left-section">
      <div className="new-tag">New*</div>
      <h1 className="tab-selection-container-title">Purchase PURE Tablet</h1>
      <p className="price">From $9.99 only</p>
      <div className="product-image">
        <div className="image-container-tab">
          {/* <button
            onClick={() => handleArrowClick("left")}
            className="arrow-left"
          >
            &lt;
          </button> */}
          <div className="tab-image-container">
            <img
              style={deviceName === "X8G" ? { transform: "scale(0.7)" } : {}}
              src={currentImage}
              alt="Phone"
              className="phone-image-tab"
            />
          </div>
          {/* <button
            onClick={() => handleArrowClick("right")}
            className="arrow-right"
          >
            &gt;
          </button> */}
        </div>
      </div>
      <MobileView>
        <div className="model-info-tab">
          <h2 className="model-title-tab">
            Model: <span className="info-icon"></span>
          </h2>

          <div
            className="model-selection"
            style={{ border: currentImage === bigTab ? "2px solid black" : "" }}
            onClick={() => setCurrentImageFunc("bigTab")}
          >
            <div className="model-details">
              <div className="model-name">G10 Tablet</div>
              <div className="model-size">10 inch display</div>
            </div>

            <div>
              {" "}
              <div className="promo-animation">Special promo</div>
              <div
                className="model-price"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                From $9.99 or{" "}
                <span style={{ textDecoration: "line-through", color: "red" }}>
                  $350
                </span>{" "}
                $99.99
              </div>{" "}
            </div>
          </div>

          <div
            className="model-selection"
            style={{
              border: currentImage === smallTab ? "2px solid black" : "",
            }}
            onClick={() => setCurrentImageFunc("smallTab")}
          >
            <div className="model-details">
              <div className="model-name">X8G Tablet</div>
              <div className="model-size">8 inch display</div>
            </div>
            <div>
              {" "}
              <div className="promo-animation">Special promo</div>
              <div
                className="model-price"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                From $9.99 or{" "}
                <span style={{ textDecoration: "line-through", color: "red" }}>
                  $350
                </span>{" "}
                $99.99
              </div>{" "}
            </div>
          </div>
        </div>
      </MobileView>
      <div className="option-title">
        <h2>Payment options:</h2>
      </div>
      <div className="payment-options-tab">
        <div
          className="payment-option"
          style={{
            border: selectedBuyOption === "Buy" ? "2px solid black" : "",
          }}
          onClick={() => selectedBuyOptions("Buy", "99.99")}
        >
          {" "}
          <h3>Buy</h3>
          <p className="payment-price">$99.99 only</p>
          <p>Pay with debit or credit card</p>
        </div>
        <div
          className="payment-option"
          style={{
            border: selectedBuyOption === "option2" ? "2px solid black" : "",
          }}
          onClick={() => selectedBuyOptions("option2", "9.99")}
        >
          <h3>Finance</h3>
          <p className="payment-price">$9.99 per month for 12 months</p>
          <p>1 year contract</p>
          <p>Pay over time at 0% APR.</p>
        </div>
        {/* <div
          className="payment-option"
          style={{
            border: selectedBuyOption === "option3" ? "2px solid black" : "",
          }}
          onClick={() => selectedBuyOptions("option3", "25")}
        >
          <h3>Finance 2</h3>
          <p className="payment-price">$9.99 per month for 12 months</p>
          <p>2 year contract</p>
          <p>Pay over time at 0% APR.</p>
        </div> */}
      </div>
      {visibilityState.paymentOption && (
        <div
          className={
            visibilityState.paymentOption ? "visible-options" : "hidden"
          }
        >
          <div className="monthly-data-plan-mobile">
            <h2>Connectivity, Choose a carrier:</h2>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div
              className="data-plan-option-mobile"
              style={{
                border:
                  selectMaxsipOrOther === "maxsip" ? "2px solid black" : "",
                paddingLeft: "40px",
                paddingRight: "40px",
                marginTop: "20px",
                marginRight: "20px",
                flex: 1, // Makes each child div take equal space
                display: "flex", // Enables flexbox layout inside this div
                alignItems: "center", // Vertically centers the content inside
                justifyContent: "center", // Horizontally centers the content inside
                textAlign: "center", // Ensures text alignment is centered (for multi-line texts)
                height: "100px",
                fontSize: "20px",
              }}
              onClick={() => maxsipOrOthers("maxsip")}
            >
              <span className="data-amount">Maxsip Plan</span>
            </div>

            <div
              className="data-plan-option-mobile"
              style={{
                border:
                  selectMaxsipOrOther === "other" ? "2px solid black" : "",
                paddingLeft: "40px",
                paddingRight: "40px",
                marginTop: "20px",
                marginRight: "20px",
                flex: 1, // Makes each child div take equal space
                display: "flex", // Enables flexbox layout inside this div
                alignItems: "center", // Vertically centers the content inside
                justifyContent: "center", // Horizontally centers the content inside
                textAlign: "center", // Ensures text alignment is centered (for multi-line texts)
                height: "100px", // Set a specific height or adjust as needed to ensure vertical centering
                marginLeft: "20px",
              }}
              onClick={() => maxsipOrOthers("other")}
            >
              <span className="data-amount" style={{ fontSize: "20px" }}>
                Connect carrier later
              </span>
            </div>
          </div>

          {visibilityState.maxsipPlan && (
            <div className="monthly-data-plan-mobile">
              <h2>Monthly Data Plan</h2>
              <div className="data-plan-options-mobile">
                <div
                  className="data-plan-option-mobile"
                  style={{
                    border:
                      SelectedPlanPrice === "$15" ? "2px solid black" : "",
                  }}
                  onClick={() => selectPrice("$15")}
                >
                  <span className="data-amount">1GB</span>
                  <span className="data-price">$15</span>
                </div>
                <div
                  className="data-plan-option-mobile"
                  style={{
                    border:
                      SelectedPlanPrice === "$20" ? "2px solid black" : "",
                  }}
                  onClick={() => selectPrice("$20")}
                >
                  <span className="data-amount">3GB</span>
                  <span className="data-price">$20</span>
                </div>
                <div
                  className="data-plan-option-mobile"
                  style={{
                    border:
                      SelectedPlanPrice === "$25" ? "2px solid black" : "",
                  }}
                  onClick={() => selectPrice("$25")}
                >
                  <span className="data-amount">5GB</span>
                  <span className="data-price">$25</span>
                </div>
                <div
                  className="data-plan-option-mobile"
                  style={{
                    border:
                      SelectedPlanPrice === "$35" ? "2px solid black" : "",
                  }}
                  onClick={() => selectPrice("$35")}
                >
                  <span className="data-amount">10GB</span>
                  <span className="data-price">$35</span>
                </div>
              </div>
            </div>
          )}
          {visibilityState.otherPlan && (
            <div
              className="data-plan-option-mobile"
              style={{
                border: SelectedPlanPrice === "$9.99" ? "2px solid black" : "",
                paddingLeft: "40px",
                paddingRight: "40px",

                marginTop: "20px",
              }}
              onClick={() => selectPrice("$9.99")}
            >
              <span className="data-price">
                <span
                  style={{
                    textDecoration: "line-through",
                    marginTop: "20px",
                    color: "red",
                  }}
                >
                  $15
                </span>
                <span style={{ color: "green" }}>{"  "}$9.99{" "}</span>for Kosher
                <br></br>
                <span className="" style={{ fontSize: "15px" }}>
                  TMobile & At&t
                </span>
              </span>
            </div>
          )}
          {/* 
          
          */}
        </div>
      )}

      {visibilityState.planSelect && (
        <div
          className={visibilityState.planSelect ? "visible-options" : "hidden"}
        >
          <BrowserView className="port-number-section-tab">
            {/* <div className="port-number-section-tab">
              <h2>Port your existing number</h2>
              <button
                onClick={() =>
                  universalFunctions.portYourExistingNumberFunction()
                }
                className="port-button-tab"
              >
                Port
              </button>
            </div> */}
            <button onClick={handleContinue} className="continue-button">
              Continue to buy
            </button>
          </BrowserView>
        </div>
      )}

      <BrowserView className="in-box-text-tab">
        <center>
          <h5>What's in the box</h5>
        </center>
        <center>
          <div className="what-in-box-tab">
            <div className="item">
              <img src={smallTab} alt="Phone & Case" />
              <p>Phone & Case</p>
            </div>
            <div className="item">
              <img src={charger} alt="Charger Cable" />
              <p>Charger Cable</p>
            </div>
            <div className="item">
              <img src={adapter} alt="Power Adapter" />
              <p>Power Adapter</p>
            </div>
          </div>
        </center>
      </BrowserView>
    </div>
  );
};

export default TabSelectionPage;

//$15 = 2789, 20$= 2907, 25$ = 3025, 35$=3143
/*
G10 = 185
g62 = 154
g65 = 152
X8g = 148
*/
