import React from "react";
import "./DeviceSelectionCard.css";
import { useNavigate } from "react-router-dom";

const DeviceSelectionCard = ({
  deviceType,
  price,
  features,
  imageUrl,
  colorOptions = [],
}) => {
  const [selectedColor, setSelectedColor] = React.useState(colorOptions[0]);
  const navigate = useNavigate();

  const handlePreorderClick = () => {
    const deviceName =
      deviceType === "Smart Phone"
        ? "G65"
        : deviceType === "Tablet"
        ? "G10"
        : deviceType === "Nova Mobile"
        ? "Nova"
        : "Max Music";
  
    navigate("/device-selection", { state: { deviceName } });
  };
  

  return (
    <div className="device-selection-card-page">
      <div className={`device-selection-card ${deviceType === "Smart Phone" ? "left-device-selection-card" : ""}`}>
        <h3 className="device-type">{deviceType}</h3>
        
        <div className="product-price">
          <span className="original-price">$350</span>
          <span className="current-price">$99.99</span>
          <span className="monthly-price">or $9.99/month for 12 months</span>
        </div>

        {colorOptions.length > 0 && (
          <div className="color-selection">
            {colorOptions.map((color) => (
              <button
                key={color}
                className={`color-option ${selectedColor === color ? "selected" : ""}`}
                style={{ backgroundColor: color }}
                onClick={() => setSelectedColor(color)}
                aria-label={`Select ${color} color`}
              />
            ))}
          </div>
        )}

        <div className={deviceType === "Tablet" ? "tab-image-scroll" : "device-image-scroll"}>
          <img src={imageUrl} alt={`${deviceType} device`} loading="lazy" />
        </div>

        <ul className={`feature-list ${deviceType === "Smart Phone" ? "left-feature-list" : ""}`}>
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>

        <button
          className={`preorder-button ${deviceType === "Smart Phone" ? "left-preorder-button" : ""}`}
          onClick={handlePreorderClick}
        >
          Order Now
        </button>
      </div>
    </div>
  );
};

export default DeviceSelectionCard;


/*




*/