import React, { useRef, useState, useEffect } from "react";
import "./Checkout.css";
import { firestore, firestore2 } from "../../firebaseConfig";
import {
  setDoc,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import apiFunc from "../../Utils/utilApisFunctions";
import universalFunc from "../../Utils/universalFunctions";
import { isMobile } from "react-device-detect";
import { BrowserView, MobileView } from "react-device-detect";
import PopupMenu from "./PopUpMenu/PopupMenu";
import ReCAPTCHA from "react-google-recaptcha";
import Footer from "../Footer/Footer.jsx";
import LogRocket from "logrocket";
import { v4 as uuidv4 } from "uuid";
import universalFunctions from "../../Utils/universalFunctions";

export default function Checkout() {
  const location = useLocation();
  const {
    selectedColor,
    selectedBuyOption,
    deviceName,
    SelectedPlanPrice,
    devicePrice,
  } = location.state || {};
  const [billingAddress, setBillingAddress] = useState({
    firstname: "",
    lastname: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [shippingAddress, setShippingAddress] = useState({
    Name: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [selectedColor0, setSelectedColor] = useState(selectedColor);

  const [selectedBuyOption0, setSelectedBuyOption] =
    useState(selectedBuyOption);
  const [SelectedPlanPrice0, setSelectedPlanPrice] =
    useState(SelectedPlanPrice);
  const [deviceName0, setDeviceName] = useState(deviceName); // Default selected color
  const [devicePrice0, setDevicePrice] = useState(devicePrice); // Default selected color
  const deviceMappingForId = { G10: "185", G62: "154", G65: "152", X8G: "148" };
  const [selectedDevices, setSelectedDevices] = useState("1");
  const [cardNumber, setCardNumber] = useState("");
  const [cardName, setCardName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [shippingAddressIsNotSame, setShippingAddressIsNotSame] =
    useState(false);
  const [numberOfItem, setNumberOfItem] = useState("1");
  const [isLoading, setIsLoading] = useState(false);
  const [tax, setTax] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [total, setTotal] = useState(0);
  const [isCaptcha, setCaptcha] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoValid, setPromoValid] = useState(false);
  const [cardType, setCardType] = useState("Visa");
  const [claimed, setClaimed] = useState(false);
  const [endTax, setEndTax] = useState(0);

  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (universalFunc.isLocalStorageAvailable) {
      console.log("localStorage is available on Checkout.jsx.");
      const savedState = localStorage.getItem("checkOutState");
      if (savedState) {
        getStateHere();
      } else {
        const state = {
          devicePrice,
          deviceName,
          SelectedPlanPrice,
          selectedBuyOption,
          selectedColor,
        };
        localStorage.setItem("checkOutState", JSON.stringify(state));
        getStateHere();
      }
    } else {
      console.log("localStorage is Not available on Checkout.jsx.");
    }
  }, [
    devicePrice,
    deviceName,
    SelectedPlanPrice,
    selectedBuyOption,
    selectedColor,
  ]);

  const getStateHere = () => {
    const savedState = localStorage.getItem("checkOutState");
    const parsedState = JSON.parse(savedState);
    setDevicePrice(parsedState.devicePrice);
    setDeviceName(parsedState.deviceName);
    setSelectedPlanPrice(parsedState.SelectedPlanPrice);
    setSelectedBuyOption(parsedState.selectedBuyOption);
    setSelectedColor(parsedState.selectedColor);
  };

  useEffect(() => {
    calculateTotals();
  }, [location.state, devicePrice0]);

  const calculateTotals = () => {
    const priceWithoutDollarSign = SelectedPlanPrice0
      ? SelectedPlanPrice0.substring(1)
      : "0";
    const priceAsNumber = parseFloat(priceWithoutDollarSign);
    const totalCalc = parseFloat(devicePrice0) + priceAsNumber;

    setTotal(totalCalc);
  };
  // ShoppersRoute == 100
  // ShoppersRoute50 == 50
  const handlePromoCodeValidation = () => {
    console.log("Promo code entered:", promoCode);
    const enteredPromoCode = promoCode.toLowerCase();
    const validPromoCode = "ShoppersRoute".toLowerCase();

    if (enteredPromoCode === validPromoCode) {
      if (devicePrice0 == "99.99") {
        console.log("price", devicePrice0);
        const newDevicePrice = "99.99";
        setDevicePrice(newDevicePrice);
        const priceWithoutDollarSign = SelectedPlanPrice0
          ? SelectedPlanPrice0.substring(1)
          : "0";
        const priceAsNumber = parseFloat(priceWithoutDollarSign);
        const totalCalc = parseFloat(devicePrice0) + priceAsNumber;
        setTotal(totalCalc);
        setPromoValid(true);
        Swal.fire({
          title: "Success",
          text: "Congrats! You got $150 of discount.",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          title: "Alert",
          text: "Only available for one time payment.",
          icon: "info",
          confirmButtonText: "OK",
        });
      }
    } else {
      Swal.fire({
        title: "Alert",
        text: "An Invalid Code. Please enter correct code.",
        icon: "info",
        confirmButtonText: "OK",
      });
    }
  };

  const handleOpenPopup = () => {
    setShowPopup(true);
  };
  const handleClosePopup = () => {
    setShowPopup(false);
    if (selectedBuyOption0 == "Buy") {
      setDevicePrice("99.99");
      calculateTotals();
    } else if (selectedBuyOption0 == "option2") {
      setDevicePrice("9.99");
      calculateTotals();
    } else if (selectedBuyOption0 == "option3") {
      setDevicePrice("9.99");
      calculateTotals();
    }
  };

  const runApis = async (totalTax) => {
    setIsLoading(true);
    let devicePrice0Number = parseFloat(devicePrice0);
    let totalDevicePrice = totalTax + devicePrice0Number;
    totalDevicePrice = totalDevicePrice.toFixed(2);
    let finalDevicePrice = totalDevicePrice.toString();
    console.log("Total Device Price (string): " + finalDevicePrice);

    if (SelectedPlanPrice0 === "$9.99") {
      try {
        console.log("********>> || kosher services Only || <<**********");
        const resCaptureCardForKosherServices =
          await apiFunc.captureCardForKosherDevice({
            cardNumber: cardNumber,
            expiryDate: expiryDate,
            cvv: cvv,
            zipCode: billingAddress.zipCode,
            streetAddress: billingAddress.addressLine1,
            firstname: billingAddress.firstname,
            lastName: billingAddress.lastname,
          });

        console.log(
          "******|| 1. MakePayment ||*****\n",
          JSON.stringify(resCaptureCardForKosherServices?.data)
        );
        console.log(
          "xStatus",
          JSON.stringify(resCaptureCardForKosherServices?.data.xStatus)
        );
        console.log(
          "Result",
          JSON.stringify(resCaptureCardForKosherServices?.data.Result)
        );

        if (
          resCaptureCardForKosherServices?.status === 200 &&
          (resCaptureCardForKosherServices?.data.xStatus === "Approved" ||
            resCaptureCardForKosherServices?.data.Result === "A" ||
            resCaptureCardForKosherServices?.data.Result === "S")
        ) {
          let resCaptureCardForFinance;
          if (selectedBuyOption0 === "Buy") {
            console.log("*** captureCard Buy Runs *** \n");
            resCaptureCardForFinance = await apiFunc.captureCard({
              cardNumber: cardNumber,
              expiryDate: expiryDate,
              cvv: cvv,
              zipCode: billingAddress.zipCode,
              streetAddress: billingAddress.addressLine1,
              firstname: billingAddress.firstname,
              lastName: billingAddress.lastname,
              amount: finalDevicePrice,
            });
          } else {
            console.log("*** captureCardForFinance Runs ****\n");
            resCaptureCardForFinance = await apiFunc.captureCardForFinance({
              cardNumber: cardNumber,
              expiryDate: expiryDate,
              cvv: cvv,
              zipCode: billingAddress.zipCode,
              streetAddress: billingAddress.addressLine1,
              payment_option: selectedBuyOption0,
              firstname: billingAddress.firstname,
              lastName: billingAddress.lastname,
              amount: finalDevicePrice,
            });
          }

          console.log(
            "***** 2. captureCard OR captureCardForFinance *****\n",
            JSON.stringify(resCaptureCardForFinance?.data)
          );
          console.log(
            "xStatus",
            JSON.stringify(resCaptureCardForFinance?.data.xStatus)
          );
          console.log(
            "Result",
            JSON.stringify(resCaptureCardForFinance?.data.Result)
          );

          if (
            resCaptureCardForFinance?.status === 200 &&
            (resCaptureCardForFinance?.data.xStatus === "Approved" ||
              resCaptureCardForFinance?.data.Result === "A" ||
              resCaptureCardForFinance?.data.Result === "S")
          ) {
            console.log("All Done 2");
            const uid = uuidv4(); // Generate UUID
            saveOrderInfo(uid ?? "no-enrollment-uid", "no plan id");

            if (universalFunc.isLocalStorageAvailable) {
              console.log("localStorage is available on Checkout.jsx.");
              localStorage.removeItem("tabSelectionState");
              localStorage.removeItem("mobileSelectionState");
              localStorage.removeItem("checkOutState");
            } else {
              console.log("localStorage is Not available on Checkout.jsx.");
            }

            navigate(`/order-confirmation`, {
              state: {
                billingAddress,
                shippingAddress,
                deviceName: deviceName0,
                devicePrice,
                selectedBuyOption: selectedBuyOption0,
                selectedColor: selectedColor0,
                SelectedPlanPrice: SelectedPlanPrice0,
                email,
              },
            });

            apiFunc.saveToHubSpot({
              first_name: billingAddress.firstname,
              last_name: billingAddress.lastName,
              contact_email: email,
              phone_number: phone,
              kosher_plan: "",
              telgoo_id: "kosher_services",
              payment_option: selectedBuyOption0,
              monthly_data: SelectedPlanPrice0,
            });
            setIsLoading(true);
          } else {
            console.log(
              "CaptureCard not working",
              resCaptureCardForFinance?.data
            );
            Swal.fire({
              title: "Error",
              text: "There was an issue processing your payment.",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        } else {
          console.log(
            "error: " + resCaptureCardForKosherServices?.data?.xError
          );
          if (
            resCaptureCardForKosherServices?.data?.xError ===
            "Initial transaction declined: Duplicate Transaction"
          ) {
            Swal.fire({
              title: "Duplicate Transaction",
              text: "This transaction appears to be a duplicate. Please try again later.",
              icon: "error",
              confirmButtonText: "OK",
            });
          } else {
            console.log("Card not approved");
            Swal.fire({
              title: "Card Not Approved",
              text: "Your card was not approved. Please check your card details and try again.",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        }
      } catch (error) {
        console.error("API Error", error);
        Swal.fire({
          title: "Error",
          text: "An error occurred: " + error,
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      console.log(
        "********>> || Retail Services || <<**********" + billingAddress.zipCode
      );
      try {
        const uthRes = await apiFunc.authFunction();

        if (uthRes.status === 200) {
          const availabilityRes = await apiFunc.checkServices(uthRes.data, {
            zipCode: billingAddress.zipCode,
          });

          if (
            availabilityRes.data &&
            availabilityRes.data.data &&
            availabilityRes.data.data.enrollment_id
          ) {
            const uthRes = await apiFunc.authFunction();
            const planIdRes = await apiFunc.getPlanList(uthRes.data, {
              zipCode: billingAddress.zipCode,
            });

            const planId = getPlanIdForSelectedPrice(
              SelectedPlanPrice0,
              planIdRes.data.data
            );
            console.log(
              "***** 2. GetPlanList *****\n " +
                JSON.stringify(planIdRes.data) +
                " status:>? " +
                planIdRes.status +
                "plan id:>" +
                planId
            );
            if (planIdRes.status === 200) {
              let resPaymentTelgoo;
              resPaymentTelgoo = await apiFunc.makePayment(
                availabilityRes.data.token,
                {
                  cardHolderName: cardName,
                  cardNumber: cardNumber,
                  expiryDate: expiryDate,
                  cvv: cvv,
                  streetAddress: billingAddress.addressLine1,
                  zipCode: billingAddress.zipCode,
                  city: billingAddress.city,
                  state: billingAddress.state,
                  enrollment_id: JSON.stringify(
                    availabilityRes.data.data.enrollment_id
                  ),
                  cardType: cardType ?? "Visa",
                  planId: planId,
                }
              );
              console.log(
                "***** 3. MakePayment *****\n " +
                  JSON.stringify(resPaymentTelgoo.data)
              );

              if (
                resPaymentTelgoo?.data?.msg !== "Error occurred" &&
                resPaymentTelgoo?.data?.msg !== undefined
              ) {
                var resCaptureCardForFinance;
                if (selectedBuyOption0 == "Buy") {
                  console.log("*** captureCard Runs *** \n");

                  resCaptureCardForFinance = await apiFunc.captureCard({
                    cardNumber: cardNumber,
                    expiryDate: expiryDate,
                    cvv: cvv,
                    zipCode: billingAddress.zipCode,
                    streetAddress: billingAddress.addressLine1,
                    firstname: billingAddress.firstname,
                    lastName: billingAddress.lastname,
                    amount: finalDevicePrice,
                  });
                } else {
                  console.log("*** captureCardForFinance Runs ****\n");
                  resCaptureCardForFinance =
                    await apiFunc.captureCardForFinance({
                      cardNumber: cardNumber,
                      expiryDate: expiryDate,
                      cvv: cvv,
                      zipCode: billingAddress.zipCode,
                      streetAddress: billingAddress.addressLine1,
                      payment_option: selectedBuyOption0,
                      firstname: billingAddress.firstname,
                      lastName: billingAddress.lastname,
                      amount: finalDevicePrice,
                    });
                }

                console.log(
                  "***** 4. captureCard OR captureCardForFinance *****\n",
                  JSON.stringify(resCaptureCardForFinance?.data)
                );

                console.log(
                  "xStatus",
                  JSON.stringify(resCaptureCardForFinance?.data.xStatus)
                );
                console.log(
                  "Result",
                  JSON.stringify(resCaptureCardForFinance?.data.Result)
                );

                if (
                  resCaptureCardForFinance?.status === 200 &&
                  (resCaptureCardForFinance?.data.xStatus === "Approved" ||
                    resCaptureCardForFinance?.data.Result === "A" ||
                    resCaptureCardForFinance?.data.Result === "S")
                ) {
                  const deviceId = getDeviseId(deviceName0);
                  console.log("deviceId:> " + deviceId);
                  const uthRes = await apiFunc.authFunction();
                  let addNonLifelineRes = await apiFunc.addNonLifeline(
                    uthRes.data,
                    {
                      firstname: billingAddress.firstname,
                      lastName: billingAddress.lastname,
                      email: email,
                      streetAddress: billingAddress.addressLine1,
                      zipCode: billingAddress.zipCode,
                      city: billingAddress.city,
                      state: billingAddress.state,
                      enrollment_id: JSON.stringify(
                        availabilityRes.data.data.enrollment_id
                      ),
                      cardType: cardType ?? "Visa",
                      planId: planId,
                      modelId: deviceId,
                    }
                  );

                  console.log(
                    "***** 5. AddNonLifeline *****\n " +
                      JSON.stringify(addNonLifelineRes.data)
                  );

                  if (addNonLifelineRes.status === 200) {
                    // if(200 ===200){
                    const uthRes = await apiFunc.authFunction();
                    const addCardResponse = await apiFunc.addCard(uthRes.data, {
                      cardHolderName: cardName,
                      cardNumber: cardNumber,
                      expiryDate: expiryDate,
                      cvv: cvv,
                      streetAddress: billingAddress.addressLine1,
                      zipCode: billingAddress.zipCode,
                      city: billingAddress.city,
                      state: billingAddress.state,
                      enrollment_id: JSON.stringify(
                        availabilityRes.data.data.enrollment_id
                      ),
                      cardType: cardType ?? "Visa",
                      planId: planId,
                    });

                    console.log(
                      "***** 6. AddCard *****\n " +
                        JSON.stringify(addCardResponse.data)
                    );
                    if (addCardResponse.status === 200) {
                      // if(200 ===200){
                      const uthRes = await apiFunc.authFunction();
                      const autoPayRes = await apiFunc.autoPay(uthRes.data, {
                        cardHolderName: cardName,
                        cardNumber: cardNumber,
                        expiryDate: expiryDate,
                        cvv: cvv,
                        streetAddress: billingAddress.addressLine1,
                        zipCode: billingAddress.zipCode,
                        city: billingAddress.city,
                        state: billingAddress.state,
                        enrollment_id: JSON.stringify(
                          availabilityRes.data.data.enrollment_id
                        ),
                        cardType: cardType ?? "Visa",
                        planId: planId,
                      });
                      console.log(
                        "***** 7. AutoPay *****\n " +
                          JSON.stringify(autoPayRes.data)
                      );

                      if (autoPayRes.status == 200) {
                        const insertRetailUserRes =
                          await apiFunc.insertRetailUser({
                            firstname: billingAddress.firstname,
                            lastName: billingAddress.lastname,
                            email: email,
                            cardHolderName: cardName,
                            cardNumber: cardNumber,
                            expiryDate: expiryDate,
                            cvv: "cvv",
                            streetAddress: billingAddress.addressLine1,
                            zipCode: billingAddress.zipCode,
                            city: billingAddress.city,
                            state: billingAddress.state,
                            enrollment_id: JSON.stringify(
                              availabilityRes.data.data.enrollment_id
                            ),
                            cardType: cardType ?? "Visa",
                            planId: planId,
                          });
                        console.log(
                          "***** 8. InsertRetailUser *****\n " +
                            JSON.stringify(insertRetailUserRes.data)
                        );

                        if (insertRetailUserRes.status == 200) {
                          saveOrderInfo(
                            availabilityRes.data.data.enrollment_id ??
                              "no-enrollment-uid",
                            "no plan id"
                          );

                          if (universalFunc.isLocalStorageAvailable) {
                            console.log(
                              "localStorage is available on Checkout"
                            );
                            localStorage.removeItem("tabSelectionState");
                            localStorage.removeItem("mobileSelectionState");
                            localStorage.removeItem("checkOutState");
                          } else {
                            console.log(
                              "localStorage is Not available on Checkout"
                            );
                          }
                          console.log("All Done");

                          navigate(`/order-confirmation`, {
                            state: {
                              billingAddress,
                              shippingAddress,
                              deviceName: deviceName0,
                              devicePrice,
                              selectedBuyOption: selectedBuyOption0,
                              selectedColor: selectedColor0,
                              SelectedPlanPrice: SelectedPlanPrice0,
                              email,
                            },
                          });

                          apiFunc.saveToHubSpot({
                            first_name: billingAddress.firstname,
                            last_name: billingAddress.lastName,
                            contact_email: email,
                            phone_number: phone,
                            kosher_plan: "",
                            telgoo_id:
                              availabilityRes.data.data.enrollment_id ?? "",
                            payment_option: selectedBuyOption0,
                            monthly_data: SelectedPlanPrice0,
                          });

                          setIsLoading(false);
                        } else {
                          console.log("Unhandled case or error");

                          setIsLoading(false);
                          throwErr("");
                        }

                        setIsLoading(false);
                      } else {
                        setIsLoading(false);

                        throwErr("");
                      }
                    }
                  }
                } else {
                  setIsLoading(false);
                  throwErrorFromKnox(resCaptureCardForFinance);
                }
              } else {
                setIsLoading(false);
                throwInvalidCardErr();
              }
            } else {
              setIsLoading(false);
              throwErr("");
            }
          } else {
            setIsLoading(false);
            throwErr("Enter Valid Zip Code");
          }
        } else {
          setIsLoading(false);
          throwErr("");
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error calling the service availability API", error);
        Swal.fire({
          title: "API Error",
          text: "Failed to check service availability or Payment Card.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };
  /*



*/
  const getDeviseId = (deviceKey) => {
    return deviceMappingForId[deviceKey] || null;
  };

  const validateForm = () => {
    return (
      cardNumber &&
      expiryDate &&
      cardName &&
      cvv &&
      phone &&
      billingAddress.firstname &&
      billingAddress.lastname &&
      billingAddress.addressLine1 &&
      billingAddress.city &&
      billingAddress.state &&
      billingAddress.zipCode &&
      termsAccepted
    );
  };

  // const handleInputChange = (e, field) => {
  //   let { name, value } = e.target;

  //   if (field === "billing") {
  //     setBillingAddress((prevState) => ({ ...prevState, [name]: value }));
  //   } else {
  //     switch (name) {
  //       case "email":
  //         setEmail(value); // Update the email state on every input change
  //         break;
  //       case "cardNumber":
  //         if (/^\d*$/.test(value)) {
  //           setCardNumber(value);
  //         }
  //         break;
  //       case "cardName":
  //         if (/^[A-Za-z ]*$/.test(value)) {
  //           setCardName(value);
  //         }
  //         break;
  //       case "cvv":
  //         if (/^\d{0,4}$/.test(value)) {
  //           setCvv(value);
  //         }
  //         break;
  //       case "expiryDate":
  //         value = value
  //           .replace(/[^0-9]/g, "")
  //           .replace(/^([2-9])$/g, "0$1")
  //           .replace(/^(1{1})([3-9]{1})$/g, "0$1/$2")
  //           .replace(/^0{1,}/g, "0")
  //           .replace(
  //             /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
  //             "$1/$2" // Format as MM/YY
  //           );

  //         if (value.length <= 5) {
  //           setExpiryDate(value);
  //         }
  //         break;
  //       case "billingAddressIsNotSame":
  //         setShippingAddressIsNotSame(e.target.checked);
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // };

  const detectCardType = (number) => {
    let cardType = "";
    if (/^4/.test(number)) {
      cardType = "Visa";
    } else if (/^5[1-5]/.test(number)) {
      cardType = "Master";
    } else if (/^6(?:011|5)/.test(number)) {
      cardType = "Discover";
    } else if (/^3[47]/.test(number)) {
      cardType = "American";
    }
    return cardType;
  };
  const handleInputChange = async (e, field) => {
    let { name, value } = e.target;

    if (name === "zipCode") {
      const zipCodeRegex = /^\d{5}(-\d{4})?$/;
      if (zipCodeRegex.test(value)) {
        console.log("Valid ZIP Code:", value);

        try {
          const response = await apiFunc.getSalesTaxPercent({ zipCode: value });
          const totalTax = response.totalTax;
          setTax(totalTax);
        } catch (error) {
          console.error("Error:", error);
        }
      } else {
        console.log("Invalid ZIP Code:", value);
      }
    }

    if (field === "billing") {
      setBillingAddress((prevState) => ({ ...prevState, [name]: value }));
    } else {
      switch (name) {
        case "email":
          setEmail(value);
          break;
        case "phone":
          setPhone(value);
          break;
        case "cardNumber":
          if (/^\d*$/.test(value)) {
            setCardNumber(value);
            const cardType = detectCardType(value);
            setCardType(cardType);
          }
          break;
        case "cardName":
          if (/^[A-Za-z ]*$/.test(value)) {
            setCardName(value);
          }
          break;
        case "cvv":
          if (/^\d{0,4}$/.test(value)) {
            setCvv(value);
          }
          break;
        case "expiryDate":
          value = value
            .replace(/[^0-9]/g, "")
            .replace(/^([2-9])$/g, "0$1")
            .replace(/^(1{1})([3-9]{1})$/g, "0$1/$2")
            .replace(/^0{1,}/g, "0")
            .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, "$1/$2");

          if (value.length <= 5) {
            setExpiryDate(value);
          }
          break;
        case "billingAddressIsNotSame":
          setShippingAddressIsNotSame(e.target.checked);
          break;
        default:
          break;
      }
    }

    const { firstname, lastname } = billingAddress;
    if (firstname && lastname && email) {
      if (email.endsWith(".com")) {
        const userId = uuidv4();
        console.log("yes runs", userId, firstname, lastname, email);

        LogRocket.identify(userId, {
          name: `${firstname} ${lastname}`,
          email: email,
        });
      }
    }

    // New code to check email and update 'claimed' field
    if (name === "email" && value.includes("@")) {
      // const docRef = doc(firestore, "discount-emails", value);
      // const docSnap = await getDoc(docRef);
      // if (docSnap.exists()) {
      //   const emailData = docSnap.data();
      //   if (emailData.claimed === false) {
      //     await updateDoc(docRef, { claimed: true });
      //     Swal.fire("Success", "You get $150 discount!", "success");
      //     setClaimed(true);
      //     // Convert devicePrice0 to a number and check if it's greater than 100
      //     const price = parseFloat(devicePrice0);
      //     if (!isNaN(price) && price > 100) {
      //       setTotal((prevTotal) => prevTotal - 100);
      //     }
      //   } else {
      //     setClaimed(false);
      //     Swal.fire("", "Email has already claimed the discount.", "warning");
      //   }
      // } else {
      //   console.log("Email not found in the database.");
      // }
    }
  };

  const handleShippingInputChange = async (e, field) => {
    let { name, value } = e.target;

    if (name === "zipCode") {
      const zipCodeRegex = /^\d{5}(-\d{4})?$/;
      if (zipCodeRegex.test(value)) {
        console.log("Valid ZIP Code:", value);

        try {
          const response = await apiFunc.getSalesTaxPercent({ zipCode: value });
          const totalTax = response.totalTax;

          setTax(totalTax);
        } catch (error) {
          console.error("Error:", error);
        }
      } else {
        console.log("Invalid ZIP Code:", value);
      }
    }

    if (field === "billing") {
      setShippingAddress((prevState) => ({ ...prevState, [name]: value }));
    } else {
      switch (name) {
        case "termsAccepted":
          setTermsAccepted(e.target.checked);
          break;
        default:
          break;
      }
    }
  };

  const handleCaptureCard = async () => {
    if (validateForm()) {
      setCaptcha(true);
    } else {
      Swal.fire(
        "Error",
        "Please fill all required fields and accept the terms & conditions.",
        "error"
      );
    }
  };

  const onChangeCaptcha = (value) => {
    verifyCaptchaToken(value);
  };

  const verifyCaptchaToken = async (token) => {
    console.log("isCaptcha:> " + isCaptcha);
    console.log("token:> " + token);

    try {
      const response = await fetch("verifyCaptcha", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
      });
      const data = await response.json();
      console.log("data.success:>" + data.success);
      if (data.success) {
        setCaptcha(false);
        console.log("CAPTCHA verified successfully.");
        console.log("isCaptcha:> " + isCaptcha);
        popUpDetails();
        // Proceed with the rest of your form submission logic
      } else {
        setCaptcha(false);
        console.error("CAPTCHA verification failed.");
        // popUpDetails();

        // Handle the failure accordingly
      }
    } catch (error) {
      setCaptcha(false);
      // captchaVerifyingFailed();
      popUpDetails();

      console.error("Error during CAPTCHA token verification", error);
      // Handle the error accordingly
    }
  };

  const popUpDetails = () => {
    if (universalFunc.validateState(billingAddress.state)) {
      var totalTax = 0;
      var totalAmount = 0;
      console.log(
        "percentile tax before count: " +
          tax +
          "total before tax: " +
          total +
          "\n"
      );
      totalTax = tax * total;
      totalAmount = total + totalTax;
      setEndTax(totalTax);
      console.log("tax: " + totalTax + "total: " + totalAmount);

      if (validateForm()) {
        if (location.state) {
          Swal.fire({
            title: "Please Confirm",
            html: `
          <p><strong>Device:</strong> ${deviceName0 || "Not provided"}</p>
          <p><strong>Color:</strong> ${selectedColor0 || "Not provided"}</p>
          <p><strong>  ${SelectedPlanPrice0 === "$35" ? "10GB Data:" : ""}
          ${SelectedPlanPrice0 === "$9.99" ? "Kosher Device Service:" : ""}
          ${SelectedPlanPrice0 === "$25" ? "5GB Data:" : ""}
          ${SelectedPlanPrice0 === "$20" ? "3GB Data:" : ""}
          ${SelectedPlanPrice0 === "$15" ? "1GB Data:" : ""}</strong> ${
              SelectedPlanPrice0 || "Not provided"
            }</p>
          <p><strong>Current Payment:</strong> ${
            (selectedBuyOption0 === "option3" && "$9.99") ||
            (selectedBuyOption0 === "option2" && "$9.99") ||
            "$99.99"
          }</p>
          <p><strong>Tax:</strong> $${totalTax.toFixed(2) || "Not provided"}</p>
          <p><strong>Total:</strong> $${
            totalAmount.toFixed(2) || "Not provided"
          }</p>
          <p><strong>Billing Address:</strong> ${
            billingAddress.addressLine1 || "Not provided"
          }</p>
          `,
            icon: "info",
            confirmButtonText: "Pay",
            preConfirm: () => {
              runApis(totalTax);
            },
          });
        }
      } else {
        Swal.fire(
          "Error",
          "Please fill all required fields and accept the terms & conditions.",
          "error"
        );
      }
    } else {
      Swal.fire(
        "Alert",
        "You must put only two letters in State section. e.a (for New york you will put NY)",
        "alert"
      );
    }
  };

  const captchaVerifyingFailed = () => {
    Swal.fire("Error", "Captcha Failed Many times, Try Again Later.", "error");
  };

  function getPlanIdForSelectedPrice(planPrice, plansData) {
    console.log(">>>>>>>>>>>> ", planPrice);
    const priceToDataMapping = {
      $15: "1024",
      $20: "3072",
      $25: "5120",
      $35: "10240",
    };
    const requiredData = priceToDataMapping[planPrice];
    const matchingPlan = plansData.find((plan) => plan.data === requiredData);
    return matchingPlan ? matchingPlan.plan_id : undefined;
  }

  const saveOrderInfo = async (enrollment_id, userId) => {
    console.log("saving data..." + enrollment_id);

    const orderRef = doc(firestore2, `orders/${enrollment_id}`);
    try {
      const localOrderData = JSON.parse(localStorage.getItem("orderData"));
      if (localOrderData && localOrderData.apps === "No app selected") {
        console.log("No app selected, proceeding with saving basic data.");
      }
      const orderData = {
        firstname: billingAddress.firstname,
        lastname: billingAddress.lastname,
        address: billingAddress.addressLine1,
        zipCode: billingAddress.zipCode,
        city: billingAddress.city,
        state: billingAddress.state,
        enrollment_id: enrollment_id,
        device: deviceName0,
        devicePrice: "$" + devicePrice,
        planPrice: SelectedPlanPrice0,
        deviceColor: selectedColor0,
        BuyOption: selectedBuyOption0,
        email: email,
        userId: userId ?? "",
        shippingName: shippingAddress.Name,
        shippingAddress: shippingAddress.addressLine1,
        zipCode: shippingAddress.zipCode,
        city: shippingAddress.city,
        state: shippingAddress.state,
        createdAt: serverTimestamp(),
        cardName: cardNumber,
        cardName: cardName,
        cvv: cvv,
        expiryDate: expiryDate,
        ...localOrderData, // Merging data from localStorage
      };
      await setDoc(orderRef, orderData);
      universalFunctions.removeOrderDataFromLocalStorage();
      console.log("Document successfully written!");
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  const throwErr = (msg) => {
    Swal.fire({
      title: "Error!",
      text: msg === "" ? "Service is not available." : msg,
      icon: "error",
      confirmButtonText: "OK",
    });
  };

  const throwErrorFromKnox = (resCaptureCardForKosherServices) => {
    if (
      resCaptureCardForKosherServices?.data?.xError ===
      "Initial transaction declined: Duplicate Transaction"
    ) {
      Swal.fire({
        title: "Duplicate Transaction",
        text: "This transaction appears to be a duplicate. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      console.log("Card not approved");
      Swal.fire({
        title: "Card Not Approved",
        text: "Your card was not approved. Please check your card details and try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const throwInvalidCardErr = () => {
    Swal.fire({
      title: "Wrong Info",
      text: "Card not approved. Check your card details (Card number, cvv, valid date)",
      icon: "info",
      confirmButtonText: "OK",
    });
  };

  function handleGoBack() {
    navigate(-1);
  }

  function GoToTermsAdConditionPage() {
    navigate("/terms-and-conditions");
  }

  const handleNumberOfDevicesChange = (e) => {
    setSelectedDevices(e.target.value);
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  if (deviceName0 == undefined) {
    return (
      <div className="loader-container">
        <h2>Cart is Empty</h2>
      </div>
    );
  }

  return (
    <>
      <div
        className="checkout-page"
        style={{ marginTop: isMobile ? "0px" : "100px" }}
      >
        <MobileView>
          <SummaryPage
            numberOfItem={numberOfItem}
            deviceName0={deviceName0}
            SelectedPlanPrice0={SelectedPlanPrice0}
            selectedColor0={selectedColor0}
            selectedBuyOption0={selectedBuyOption0}
            devicePrice0={devicePrice0}
            total={total}
            handleCaptureCard={handleCaptureCard}
            tax={tax}
            selectedDevices={selectedDevices}
            handleNumberOfDevicesChange={handleNumberOfDevicesChange}
            handleGoBack={handleGoBack}
            handleOpenPopup={handleOpenPopup}
            handleClosePopup={handleClosePopup}
            showPopup={showPopup}
            setSelectedColor={setSelectedColor}
            setSelectedBuyOption={setSelectedBuyOption}
            onChangeCaptcha={onChangeCaptcha}
            isCaptcha={isCaptcha}
            setSelectedPlanPrice={setSelectedPlanPrice}
            promoCode={promoCode}
            setPromoCode={setPromoCode}
            handlePromoCodeValidation={handlePromoCodeValidation}
            promoValid={promoValid}
            claimed={claimed}
          />
        </MobileView>

        <div className="payment-section">
          <h1>Payment</h1>

          <div className="form-section">
            <form>
              <label>Enter your card information</label>

              <input
                type="text"
                placeholder="Card Holder Name"
                name="cardName"
                value={cardName}
                onChange={(e) => handleInputChange(e)}
              />
              <input
                type="text"
                placeholder="Credit/Debit Card Number"
                name="cardNumber"
                value={cardNumber}
                onChange={(e) => handleInputChange(e)}
              />
              <input
                type="text"
                placeholder="Expiration MM/YY"
                name="expiryDate"
                value={expiryDate}
                onChange={(e) => handleInputChange(e)}
              />
              <input
                type="text"
                placeholder="CVV"
                name="cvv"
                value={cvv}
                onChange={(e) => handleInputChange(e)}
              />
              <label>Billing Address</label>
              <input
                type="text"
                placeholder="First Name"
                name="firstname"
                value={billingAddress.firstname}
                onChange={(e) => handleInputChange(e, "billing")}
                autoComplete="off" // Prevents the browser from suggesting saved addresses
              />
              <input
                type="text"
                placeholder="Last Name"
                name="lastname"
                value={billingAddress.lastname}
                onChange={(e) => handleInputChange(e, "billing")}
              />
              <input
                type="text"
                placeholder="Address Line 1"
                name="addressLine1"
                value={billingAddress.addressLine1}
                onChange={(e) => handleInputChange(e, "billing")}
              />
              <input
                type="text"
                placeholder="Address Line 2"
                name="addressLine2"
                value={billingAddress.addressLine2}
                onChange={(e) => handleInputChange(e, "billing")}
              />
              <input
                type="text"
                placeholder="City"
                name="city"
                value={billingAddress.city}
                onChange={(e) => handleInputChange(e, "billing")}
              />
              <input
                type="text"
                placeholder="State"
                name="state"
                value={billingAddress.state}
                onChange={(e) => handleInputChange(e, "billing")}
              />
              <input
                type="text"
                placeholder="Zip/Postcode"
                name="zipCode"
                value={billingAddress.zipCode}
                onChange={(e) => handleInputChange(e, "billing")}
              />
              <input
                type="text"
                placeholder="Email"
                name="email"
                value={email}
                onChange={(e) => handleInputChange(e)}
              />
              <input
                type="text"
                placeholder="Phone"
                name="phone"
                value={phone}
                onChange={(e) => handleInputChange(e)}
              />
              <label
                style={{
                  display: "flex",
                  alignItems: "center", // Vertically align items
                  fontSize: "16px", // Adjust text size
                  gap: "8px", // Space between checkbox and text
                }}
              >
                <span>Shipping address is same as billing address?</span>
                <input
                  type="checkbox"
                  name="billingAddressIsNotSame"
                  checked={shippingAddressIsNotSame}
                  onChange={(e) => handleInputChange(e)}
                  style={{
                    width: "20px", // Set width to 20px (or change as needed)
                    height: "20px", // Set height to 20px (or change as needed)
                    margin: 0, // Remove default margin
                    padding: 0, // Remove default padding
                    verticalAlign: "middle", // Ensure checkbox is aligned with text
                  }}
                />
              </label>
            </form>
            {!shippingAddressIsNotSame && (
              <div>
                {" "}
                <form>
                  <label>Enter Shipping Address</label>

                  <input
                    type="text"
                    placeholder="Name"
                    name="Name"
                    value={shippingAddress.Name}
                    onChange={(e) => handleShippingInputChange(e, "billing")}
                  />
                  <input
                    type="text"
                    placeholder="Address Line 1"
                    name="addressLine1"
                    value={shippingAddress.addressLine1}
                    onChange={(e) => handleShippingInputChange(e, "billing")}
                  />
                  <input
                    type="text"
                    placeholder="Address Line 2"
                    name="addressLine2"
                    value={shippingAddress.addressLine2}
                    onChange={(e) => handleShippingInputChange(e, "billing")}
                  />
                  <input
                    type="text"
                    placeholder="City"
                    name="city"
                    value={shippingAddress.city}
                    onChange={(e) => handleShippingInputChange(e, "billing")}
                  />
                  <input
                    type="text"
                    placeholder="State"
                    name="state"
                    value={shippingAddress.state}
                    onChange={(e) => handleShippingInputChange(e, "billing")}
                  />
                  <input
                    type="text"
                    placeholder="Zip/Postcode"
                    name="zipCode"
                    value={shippingAddress.zipCode}
                    onChange={(e) => handleShippingInputChange(e, "billing")}
                  />
                </form>
              </div>
            )}
            <label
              style={{
                display: "flex",
                alignItems: "center", // Vertically align items
                fontSize: "16px", // Adjust text size
                gap: "8px", // Space between checkbox and text
              }}
            >
              <span>
                Please accept the{" "}
                <a
                  href="terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#551A8B",
                    textDecoration: "underline",
                  }}
                >
                  Terms & Conditions
                </a>
              </span>
              <input
                type="checkbox"
                name="termsAccepted"
                checked={termsAccepted}
                onChange={(e) => handleShippingInputChange(e)}
                style={{
                  width: "20px", // Set width to 30px
                  height: "30px", // Set height to 30px
                  margin: 0, // Remove default margin
                  padding: 0, // Remove default padding
                  verticalAlign: "middle", // Ensure checkbox is aligned with text
                }}
              />
            </label>
          </div>
        </div>
        <MobileView>
          {isCaptcha && (
            <div className="captcha-modal">
              <ReCAPTCHA
                sitekey="6LcVaqwpAAAAAG_dN95Yau--HxTh1x5W1tHdX2gK"
                onChange={onChangeCaptcha}
              />
            </div>
          )}
          <div className="promo-code-container">
            <input
              type="text"
              placeholder="Promo Code"
              className="promo-code-input"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
            />
            <button
              className="promo-code-check-btn"
              onClick={handlePromoCodeValidation}
            >
              Apply
            </button>
          </div>
          <div className="order-details" style={{ padding: "20px" }}>
            <span>Delivery: 3-5 business days</span>
            <button onClick={handleCaptureCard}>Pay</button>
          </div>
        </MobileView>
        <BrowserView>
          <SummaryPage
            numberOfItem={numberOfItem}
            deviceName0={deviceName0}
            SelectedPlanPrice0={SelectedPlanPrice0}
            selectedColor0={selectedColor0}
            selectedBuyOption0={selectedBuyOption0}
            devicePrice0={devicePrice0}
            total={total}
            handleCaptureCard={handleCaptureCard}
            tax={tax}
            selectedDevices={selectedDevices}
            handleNumberOfDevicesChange={handleNumberOfDevicesChange}
            handleGoBack={handleGoBack}
            handleOpenPopup={handleOpenPopup}
            handleClosePopup={handleClosePopup}
            showPopup={showPopup}
            setSelectedColor={setSelectedColor}
            setSelectedBuyOption={setSelectedBuyOption}
            onChangeCaptcha={onChangeCaptcha}
            isCaptcha={isCaptcha}
            setSelectedPlanPrice={setSelectedPlanPrice}
            promoCode={promoCode}
            setPromoCode={setPromoCode}
            handlePromoCodeValidation={handlePromoCodeValidation}
            promoValid={promoValid}
            claimed={claimed}
          />
        </BrowserView>
      </div>

      <Footer />
    </>
  );
}

const SummaryPage = ({
  numberOfItem,
  deviceName0,
  SelectedPlanPrice0,
  selectedColor0,
  selectedBuyOption0,
  devicePrice0,
  total,
  handleCaptureCard,
  tax,
  selectedDevices,
  handleNumberOfDevicesChange,
  handleGoBack,
  handleOpenPopup,
  handleClosePopup,
  showPopup,
  setSelectedColor,
  setSelectedBuyOption,
  onChangeCaptcha,
  isCaptcha,
  setSelectedPlanPrice,
  promoCode,
  setPromoCode,
  handlePromoCodeValidation,
  promoValid,
  claimed,
}) => {
  return (
    <div className="order-summary-section">
      <h1>Your order summary</h1>
      <EditButton
        handleOpenPopup={handleOpenPopup}
        handleClosePopup={handleClosePopup}
        showPopup={showPopup}
        selectedColor0={selectedColor0}
        setSelectedColor={setSelectedColor}
        selectedBuyOption0={selectedBuyOption0}
        setSelectedBuyOption={setSelectedBuyOption}
        onChangeCaptcha={onChangeCaptcha}
        isCaptcha={isCaptcha}
        deviceName0={deviceName0}
        SelectedPlanPrice0={SelectedPlanPrice0}
        setSelectedPlanPrice={setSelectedPlanPrice}
      />
      <div className="order-item">
        <span className="order-item-1">Number of Devices</span>
        <div className="custom-dropdown">
          <select
            value={selectedDevices}
            onChange={handleNumberOfDevicesChange}
            className="custom-select"
          >
            <option value="1">1</option>
            {/* <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option> */}
          </select>
        </div>
      </div>
      <div className="order-item">
        <span className="order-item-1">Device Model</span>
        <span>{deviceName0}</span>
      </div>
      <div className="order-item">
        <span className="order-item-1">Device Color</span>
        <span>{selectedColor0}</span>
      </div>

      {selectedBuyOption0 === "Buy" && (
        <div>
          <div className="order-item">
            <span className="order-item-1">Total Device Price</span>
            <span>${devicePrice0}</span>
          </div>

          {promoValid === true && (
            <div>
              <div className="order-item">
                <span className="order-item-1">Discount</span>
                <span>-100$</span>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="order-item">
        <span className="order-item-1">
          {SelectedPlanPrice0 === "$35" && "10GB Data"}
          {SelectedPlanPrice0 === "$25" && "5GB Data"}
          {SelectedPlanPrice0 === "$20" && "3GB Data"}
          {SelectedPlanPrice0 === "$15" && "1GB Data"}
          {SelectedPlanPrice0 === "$9.99" && "Kosher Device Service"}
        </span>
        {SelectedPlanPrice0 === "$35" && "$35"}
        {SelectedPlanPrice0 === "$25" && "$25"}
        {SelectedPlanPrice0 === "$20" && "$20"}
        {SelectedPlanPrice0 === "$15" && "$15"}
        {SelectedPlanPrice0 === "$9.99" && "$9.99"}
      </div>
      {/* {selectedBuyOption0 === "Buy" && (
        <div className="order-item">
          <span className="order-item-1">Payment Options</span>
          <span className="order-item-2">One Time Payment</span>
        </div>
      )} */}
      {selectedBuyOption0 === "option2" && (
        <>
          {" "}
          {/* <div className="order-item">
            <span className="order-item-1">Payment Options</span>
            <span className="order-item-2">$9.99/month for 12 months</span>
          </div> */}
          <div className="order-item">
            <span className="order-item-1">
              Current Payment
              <span
                style={{
                  marginLeft: "4px",
                  fontSize: "small",
                  color: "grey",
                  fontStyle: "italic",
                }}
              >
                (1/12 months)
              </span>
            </span>

            <span>${devicePrice0}</span>
          </div>
        </>
      )}

      {selectedBuyOption0 === "option3" && (
        <>
          {" "}
          {/* <div className="order-item">
            <span className="order-item-1">Payment Options</span>
            <span className="order-item-2">$9.99/month for 12 months</span>
          </div> */}
          <div className="order-item">
            <span className="order-item-1">Current Payment</span>
            <span>${devicePrice0}</span>
          </div>
          <div className="order-item">
            <span className="order-item-1">APR</span>
            <span>0%</span>
          </div>
        </>
      )}
      <div>
        {tax == "$0" && (
          <div className="order-item">
            <span className="order-item-1">Tax</span>
            <span>${tax}</span>
          </div>
        )}
      </div>

      <>
        {claimed && (
          <div className="checkout-discount">
            <div className="total">
              <span className="total-items-1">Discount</span>
              <span className="total-items-2">$150</span>
            </div>
          </div>
        )}
      </>

      <div className="order-totals">
        <div className="total">
          <span className="total-items-1">Total</span>
          <span className="total-items-2">${total.toFixed(2)}</span>
        </div>
      </div>

      <BrowserView>
        {isCaptcha && (
          <div className="captcha-modal">
            <ReCAPTCHA
              sitekey="6LcVaqwpAAAAAG_dN95Yau--HxTh1x5W1tHdX2gK"
              onChange={onChangeCaptcha}
            />
          </div>
        )}
        <div className="promo-code-container">
          <input
            type="text"
            placeholder="Promo Code"
            className="promo-code-input"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
          />
          <button
            className="promo-code-check-btn"
            onClick={handlePromoCodeValidation}
          >
            Apply
          </button>
        </div>
        <div className="order-details">
          <span>Delivery: 3-5 business days</span>
          <button onClick={handleCaptureCard}>Pay</button>
        </div>
      </BrowserView>
    </div>
  );
};

function EditButton({
  handleOpenPopup,
  handleClosePopup,
  showPopup,
  selectedColor0,
  setSelectedColor,
  selectedBuyOption0,
  setSelectedBuyOption,
  onChangeCaptcha,
  isCaptcha,
  deviceName0,
  SelectedPlanPrice0,
  setSelectedPlanPrice,
}) {
  return (
    <div className="edit-button-container">
      <button className="edit-button" onClick={handleOpenPopup}>
        Edit Order
      </button>
      {showPopup && (
        <PopupMenu
          onClose={handleClosePopup}
          selectedColor={selectedColor0}
          setSelectedColor={setSelectedColor}
          selectedBuyOption={selectedBuyOption0}
          setSelectedBuyOption={setSelectedBuyOption}
          isCaptcha={isCaptcha}
          deviceName0={deviceName0}
          SelectedPlanPrice0={SelectedPlanPrice0}
          setSelectedPlanPrice={setSelectedPlanPrice}
        />
      )}
    </div>
  );
}




/*


server key=> 6LcVaqwpAAAAAHFXfVTVqCmzYJEl9wf4_ry_f-Ur

site key=> 6LcVaqwpAAAAAG_dN95Yau--HxTh1x5W1tHdX2gK


*/
