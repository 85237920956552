import React, { useState, useEffect } from "react";
import "./DesktopView.css";
import DeviceSelectionCard from "../DeviceSelectionCard/Device_selection_card";
import tabLogo from "../../../assets/pure-tab-with-logo.png";
import mobile from "../../../assets/pure-mobile-with-logo.png";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer/Footer";
import KosherImageSlider from "../ImageSlider/KosherImageSlider";

export const DesktopSide = () => {
  const navigate = useNavigate();
  const handleBuyNowClick = () => {
    navigate("/our-products");
  };
  const devices = [
    {
      name: "Tablet",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Fkosher-new%2FTABLETSIDEWAYS%20(1).png?alt=media&token=2756a270-be73-40b5-8cf5-75ae0e971f8c",
    },
    {
      name: "Music Player",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Fkosher-new%2FMUSICPLAYA.png?alt=media&token=20dc759e-e39b-42bc-b88b-5fbefe6ef3fc",
    },
    {
      name: "Nova Pure Max",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Fkosher-new%2FNOVAPUREMAX%202.png?alt=media&token=352075b2-638a-4ecc-bd1c-3f85b3c8e3c2",
    },
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % devices.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [devices.length]);

  const styles = {
    deviceWrapper: {
      position: "relative",
      width: "80%", // Full width of the container
      height: "auto",
      overflow: "hidden", // Hide overflow if the image is larger
      textAlign: "center",
    },
    floatingDevice: {
      width: "80%", // Adjust width to fit the design
      height: "auto",
      transition: "opacity 1s ease-in-out", // Smooth transition for fading effect
      display: "block",
      margin: "0 auto",
    },
    deviceHighlight: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "80%",
      height: "80%",
      // border: "2px solid rgba(255, 255, 255, 0.2)",
      borderRadius: "10px",
    },
    deviceName: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgba(0, 174, 239, 0.15)",
      border: "1px solid rgba(0, 174, 239, 0.3)",
      padding: "10px 20px",
      borderRadius: "25px",
      color: "#00aeef",
      fontSize: "15px",
      fontWeight: "600",
      marginTop: "15px",
      backdropFilter: "blur(8px)",
      boxShadow: "0 4px 12px rgba(0, 174, 239, 0.2)",
      transition: "all 0.3s ease",
      alignSelf: "center", // Center the name below the image
    },
    deviceDescription: {
      fontSize: "14px",
      color: "#666",
      marginTop: "5px",
    },
  };
  return (
    <div className="desktop-side">
      <div className="hero-section">
        <div className="hero-background">
          <div className="background-pattern"></div>
        </div>
        <div className="hero-content">
          <div className="hero-text">
            <div className="hero-badge">Kosher Certified Technology</div>
            <h1>Experience Pure Innovation</h1>
            <div className="hero-features">
              <div className="hero-feature">
                <span className="feature-icon">✓</span>
                <span>Enhanced Privacy & Security</span>
              </div>
              <div className="hero-feature">
                <span className="feature-icon">✓</span>
                <span>Browser-Free Design</span>
              </div>
              <div className="hero-feature">
                <span className="feature-icon">✓</span>
                <span>Curated App Experience</span>
              </div>
            </div>
            <button className="cta-button" onClick={handleBuyNowClick}>
              Explore Devices
            </button>
          </div>
          <div className="hero-device">
            <div style={styles.deviceWrapper}>
              {devices.map((device, index) => (
                <div
                  key={index}
                  style={{
                    opacity: currentImageIndex === index ? 1 : 0,
                    transition: "opacity 1s ease-in-out",
                  }}
                >
                  <img
                    src={device.imageUrl}
                    alt={device.name}
                    style={{
                      ...styles.floatingDevice,
                      position:
                        currentImageIndex === index ? "relative" : "absolute",
                    }}
                  />
                  {currentImageIndex === index && (
                    <>
                      <div style={styles.deviceName}>{device.name}</div>
                    </>
                  )}
                </div>
              ))}
              <div style={styles.deviceHighlight}></div>
            </div>
          </div>
        </div>
      </div>

      <div className="devices-section">
        <div className="devices-content">
          <h2>Choose Your Device</h2>
          <p>
            Select from our range of kosher-certified devices designed to meet
            your needs
          </p>
          <div className="devices-row">
            <DeviceSelectionCard
              deviceType="G65 Smart Phone"
              price="$99.99 or $9.99/month for 12 months"
              features={[
                "Customized OS",
                "Sim-Locked to Device",
                "Kosher App Store",
              ]}
              imageUrl={
                "https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Fdevices-all%2Fdialin%20phone-kosher-logo%202.png?alt=media&token=6f17b310-7aff-4bf1-a889-c02674fa53ba"
              }
              colorOptions={[]}
            />
    
            <DeviceSelectionCard
              deviceType="Nova Smart Phone"
              price="$99.99 or $9.99/month for 12 months"
              features={["Basic Features Only", "Call & Text", "No App Store"]}
              imageUrl={
                "https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Fkosher-new%2FNOVAPUREMAX%202.png?alt=media&token=352075b2-638a-4ecc-bd1c-3f85b3c8e3c2"
              }
              colorOptions={[]}
            />
            <DeviceSelectionCard
              deviceType="Music Device"
              price="$99.99 or $9.99/month for 12 months"
              features={[
                "Music Player Only",
                "Curated Content",
                "Simple Interface",
              ]}
              imageUrl={
                "https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Fkosher-new%2FMUSICPLAYA.png?alt=media&token=20dc759e-e39b-42bc-b88b-5fbefe6ef3fc"
              }
              colorOptions={[]}
            />
                    <DeviceSelectionCard
              deviceType="Tablet"
              price="$99.99 or $9.99/month for 12 months"
              features={[
                "Customized OS",
                "Sim-Locked to Device",
                "Kosher App Store",
              ]}
              imageUrl={
                "https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Fkosher-new%2FTABLETSIDEWAYS%20(1).png?alt=media&token=2756a270-be73-40b5-8cf5-75ae0e971f8c"
              }
              colorOptions={[]}
            />
          </div>
        </div>
      </div>

      <div className="os-section">
        <div className="os-content">
          <h2>Custom-Built Operating System</h2>
          <p>
            Experience unparalleled control and security with our ground-up OS
            development
          </p>

          <div className="os-features">
            <div className="os-feature">
              <h3>Built From Ground Up</h3>
              <p>
                Our operating system is custom-built from scratch, ensuring
                complete control over every aspect of your device's
                functionality.
              </p>
            </div>
            <div className="os-feature">
              <h3>No MDM Required</h3>
              <p>
                Unlike other solutions that rely on Mobile Device Management,
                our OS provides native control and security features.
              </p>
            </div>
            <div className="os-feature">
              <h3>Full Customization</h3>
              <p>
                Every aspect of the system is tailored to meet kosher
                requirements while maintaining a seamless user experience.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="features-detail-section">
        <div className="features-content">
          <div className="feature-block">
            <h2>Security Features</h2>
            <div className="feature-grid">
              <div className="feature-item">
                <h3>Browser Free</h3>
                <p>
                  True browser-free experience with no possible workarounds.
                </p>
              </div>
              <div className="feature-item">
                <h3>Enhanced Privacy</h3>
                <p>
                  Built-in security features to protect your personal
                  information.
                </p>
              </div>
              <div className="feature-item">
                <h3>Sim-Locked</h3>
                <p>Device locked to prevent unauthorized modifications.</p>
              </div>
              <div className="feature-item">
                <h3>24/6 Support</h3>
                <p>Dedicated customer service team ready to assist you.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="app-store-section">
        <KosherImageSlider />
      </div>

      <Footer />
    </div>
  );
};

export default DesktopSide;
