// ConfirmationPage.jsx
import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import html2pdf from "html2pdf.js";
import "./ConfirmationPage.css"; // Include your updated CSS styles

const ConfirmationPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const confirmationRef = useRef();

  if (!state) {
    return <div className="error">Invalid order information.</div>;
  }

  const {
    billingAddress,
    shippingAddress,
    deviceName,
    devicePrice,
    selectedKosherLevel,
    selectedBuyOption,
    selectedColor,
    SelectedPlanPrice,
    email,
  } = state;

  // Handle printing
  const handlePrint = () => {
    window.print();
  };

  // Handle Save as PDF
  const handleSaveAsPdf = () => {
    const element = confirmationRef.current;
    const options = {
      margin: 0.5,
      filename: `Order_Confirmation.pdf`,
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().set(options).from(element).save();
  };

  return (
    <div className="confirmation-page animated-page" ref={confirmationRef}>
      <img
        src="https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Frandom-imgs%2Fconfirm-27.png?alt=media&token=70653e12-a248-4659-a9da-277c288aca73"
        alt="Confirmation"
        style={{
          width: "90%",
          maxWidth: "150px",
          display: "block",
          margin: "0 auto 20px",
          marginTop: "50px",
        }}
      />
      <h1>Order Confirmation</h1>
      <center>
        <p>
          Your order has been successfully placed. Below are your order details:
        </p>
      </center>
      <center>
        {" "}
        <button className="btn save-pdf-btn" onClick={handleSaveAsPdf}>
          Save as PDF
        </button>
      </center>
      <div className="order-section">

        <div className="section">
          <h3>Billing Information</h3>
          <p>
            Name: {billingAddress.firstname} {billingAddress.lastname}
          </p>
          <p>
            Address: {billingAddress.addressLine1}, {billingAddress.city},{" "}
            {billingAddress.state}, {billingAddress.zipCode}
          </p>
          <p>Email: {email}</p>
        </div>

        <div className="section">
          <h3>Order Information</h3>
          <p>Device: {deviceName}</p>
          <p>Device Color: {selectedColor}</p>
          <p>Device Price: {devicePrice}</p>
          <p>Plan Price: {SelectedPlanPrice}</p>
          <p>Kosher Type: {selectedKosherLevel}</p>
          <p>Buy Option: {selectedBuyOption}</p>
        </div>
      </div>

      <div className="actions">
        {/* <button className="btn print-btn" onClick={handlePrint}>Print</button> */}
        <button className="btn home-btn" onClick={() => navigate("/")}>
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default ConfirmationPage;
