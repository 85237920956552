import React, { useState, useEffect } from "react";
import mobileBox from "../../assets/mobile-in-box.png";
import charger from "../../assets/charger.png";
import adapter from "../../assets/adapter.png";
import mobile6 from "../../assets/dialin phone-kosher-logo 2.png";
import mobile3 from "../../assets/all_phones/pink.png";
import mobile5 from "../../assets/all_phones/grey.png";
import mobile4 from "../../assets/all_phones/turq.png";
import mobile1 from "../../assets/all_phones/white.png";
import mobile2 from "../../assets/all_phones/navy.png";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { BrowserView, MobileView } from "react-device-detect";
import universalFunctions from "../../Utils/universalFunctions";

// Sample images object (replace these paths with your actual images)
const imagesByColor = {
  color1: mobile1,
  color2: mobile2,
  color3: mobile3,
  color4: mobile4,
  color5: mobile5,
  color6: mobile6,
  // Add more colors and their corresponding images
};

function NovaDeviceSelectionPage() {
  const navigate = useNavigate();
  const imageKeys = Object.keys(imagesByColor);
  if (universalFunctions.isLocalStorageAvailable) {
    var savedState = JSON.parse(localStorage.getItem("mobileSelectionState"));
  }
  const [currentImage, setCurrentImage] = useState(
    savedState ? savedState.currentImage : imagesByColor.color6
  );
  const [currentIndex, setCurrentIndex] = useState(
    savedState ? savedState.currentIndex : 0
  );
  const [selectedColor, setSelectedColor] = useState(
    savedState ? savedState.selectedColor : "#353432"
  );
  const [selectedKosherLevel, setSelectedKosherLevel] = useState(
    savedState ? savedState.selectedKosherLevel : ""
  );
  const [selectedBuyOption, setSelectedBuyOption] = useState(
    savedState ? savedState.selectedBuyOption : ""
  );
  const [SelectedPlanPrice, setSelectedPlanPrice] = useState(
    savedState ? savedState.SelectedPlanPrice : ""
  );
  const [selectMaxsipOrOther, setSelectMaxsipOrOther] = useState(
    savedState ? savedState.selectMaxsipOrOther : ""
  );
  const [deviceName, setDeviceName] = useState(
    savedState ? savedState.deviceName : "Nova"
  ); // Default selected color
  const [devicePrice, setDevicePrice] = useState(
    savedState ? savedState.devicePrice : "250"
  ); // Default selected color
  const [isActive, setIsActive] = useState(false);
  const [hoveredColor, setHoveredColor] = useState("");
  const [visibilityState, setVisibilityState] = useState({
    color: false,
    paymentOption: false,
    planSelect: false,
    maxsipPlan: false,
    otherPlan: false,
  });
  const [isPaymentOptionVisible, setIsPaymentOptionVisible] = useState(false);

  const colorOptions = [
    { name: "Black", code: "#353432", variable: "color6", soldOut: false },
    // { name: "White", code: "#fefeff", variable: "color1", soldOut: false },
    // { name: "Turq", code: "#7796b8", variable: "color4", soldOut: false },
    // { name: "Pink", code: "#f7ddff", variable: "color3", soldOut: false },
    // { name: "Navy", code: "#6092b1", variable: "color2", soldOut: false },
    // { name: "Grey", code: "#6c6e81", variable: "color5", soldOut: false },
  ];

  const handleArrowClick = (direction) => {
    console.log(direction);
    let newIndex = currentIndex + (direction === "left" ? -1 : 1);
    if (newIndex < 0) newIndex = imageKeys.length - 1;
    if (newIndex >= imageKeys.length) newIndex = 0;
    setCurrentImage(imagesByColor[imageKeys[newIndex]]);
    setCurrentIndex(newIndex);
    setIsActive(false); // First, make the image invisible
    setTimeout(() => {
      setCurrentImage(imagesByColor[imageKeys[newIndex]]);
      setCurrentIndex(newIndex);
      setIsActive(true); // Reveal image after a short delay
    }, 10);
  };
  useEffect(() => {
    let timer = setTimeout(() => {
      setIsActive(true); // Make the image visible after a short delay
    }, 50); // Delay to allow the image to be changed before fading in

    return () => clearTimeout(timer);
  }, [currentIndex]); // Listen for changes in currentIndex or currentImage as needed

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array means this effect runs only on mount

  useEffect(() => {
    if (universalFunctions.isLocalStorageAvailable) {
      if (savedState) {
        const savedState = localStorage.getItem("mobileSelectionState");
        const parsedState = JSON.parse(savedState);
      }
      const state = {
        currentImage,
        currentIndex,
        selectedColor,
        selectedKosherLevel,
        selectedBuyOption,
        SelectedPlanPrice,
        deviceName,
        devicePrice,
      };
      localStorage.setItem("mobileSelectionState", JSON.stringify(state));
    }
  }, [
    currentImage,
    currentIndex,
    selectedColor,
    selectedKosherLevel,
    selectedBuyOption,
    SelectedPlanPrice,
    deviceName,
    devicePrice,
  ]);

  const changeColor = (color) => {
    setVisibilityState((prevState) => ({
      ...prevState,
      color: true,
    }));
    setSelectedColor(color);

    if (color === "#fefeff") {
      setSelectedColor("white");
      setCurrentImage(imagesByColor.color1);
      setCurrentIndex(0);
    } else if (color === "#7796b8") {
      setSelectedColor("turq");

      setCurrentImage(imagesByColor.color2);
      setCurrentIndex(1);
    } else if (color === "#f7ddff") {
      setSelectedColor("pink");

      setCurrentImage(imagesByColor.color3);
      setCurrentIndex(2);
    } else if (color === "#6092b1") {
      setSelectedColor("navy");

      setCurrentImage(imagesByColor.color4);
      setCurrentIndex(3);
    } else if (color === "#6c6e81") {
      setSelectedColor("grey");

      setCurrentImage(imagesByColor.color5);
      setCurrentIndex(4);
    } else if (color === "#353432") {
      setSelectedColor("black");

      setCurrentImage(imagesByColor.color6);
      setCurrentIndex(5);
    }
  };
  const handleContinue = () => {
    const updatedColor = "Black";
    setSelectedColor(updatedColor);

    // Check if any of the selections is empty
    if (
      // !selectedKosherLevel ||
      !updatedColor ||
      !selectedBuyOption ||
      !SelectedPlanPrice
    ) {
      Swal.fire({
        title: "Missing Selection",
        text: "Please make sure all selections are made before continuing.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else {
      console.log(`Selected Kosher Level: ${selectedKosherLevel}`);
      console.log(`Selected Color: ${updatedColor}`);
      console.log(`Selected buy option: ${selectedBuyOption}`);
      console.log(`Selected Plan price: ${SelectedPlanPrice}`);
      console.log(`Deivse Name: ${deviceName}`);
      console.log(`Deivse Price: ${devicePrice}`);
      if (universalFunctions.isLocalStorageAvailable) {
        localStorage.removeItem("checkOutState");
      }
      navigate("/checkout", {
        state: {
          selectedKosherLevel,
          selectedColor: updatedColor, // Use the local variable here
          selectedBuyOption,
          deviceName,
          SelectedPlanPrice,
          devicePrice,
        },
      });
    }
  };

  const selectedBuyOptions = (option, price) => {
    setVisibilityState((prevState) => ({
      ...prevState,
      paymentOption: true,
    }));
    setSelectedBuyOption(option);
    setDevicePrice(price);
  };

  const selectPrice = (price) => {
    setVisibilityState((prevState) => ({
      ...prevState,
      planSelect: true,
    }));
    setSelectedPlanPrice(price);
  };

  const setSelectedKosherLevelFun = (lvl) => {
    setSelectedKosherLevel(lvl);
  };

  const maxsipOrOthers = (option) => {
    setSelectMaxsipOrOther(option);

    if (option === "maxsip") {
      setVisibilityState((prevState) => ({
        ...prevState,
        maxsipPlan: true,
      }));
      setVisibilityState((prevState) => ({
        ...prevState,
        otherPlan: false,
      }));
      setVisibilityState((prevState) => ({
        ...prevState,
        planSelect: false,
      }));
    } else {
      setVisibilityState((prevState) => ({
        ...prevState,
        otherPlan: true,
        maxsipPlan: false,
      }));
      setVisibilityState((prevState) => ({
        ...prevState,
        planSelect: true,
      }));
      setSelectedPlanPrice("$9.99");
    }
  };

  return (
    <div className="mobile-selection-container">
      <LeftSide
        handleArrowClick={handleArrowClick}
        currentImage={currentImage}
        selectedBuyOptions={selectedBuyOptions}
        selectedBuyOption={selectedBuyOption}
        selectPrice={selectPrice}
        SelectedPlanPrice={SelectedPlanPrice}
        handleContinue={handleContinue}
        isActive={isActive}
        changeColor={changeColor}
        selectedColor={selectedColor}
        colorOptions={colorOptions}
        setHoveredColor={setHoveredColor}
        hoveredColor={hoveredColor}
        visibilityState={visibilityState}
        maxsipOrOthers={maxsipOrOthers}
        selectMaxsipOrOther={selectMaxsipOrOther}
      />
      <RightSide
        changeColor={changeColor}
        selectedColor={selectedColor}
        setSelectedKosherLevel={setSelectedKosherLevelFun}
        selectedKosherLevel={selectedKosherLevel}
        handleContinue={handleContinue}
        colorOptions={colorOptions}
        setHoveredColor={setHoveredColor}
        hoveredColor={hoveredColor}
        visibilityState={visibilityState}
        selectedBuyOption={selectedBuyOption}
        SelectedPlanPrice={SelectedPlanPrice}
      />
      <MobileView>
        <center className="in-box-text-mobile">What's in the box</center>
        <center>
          <div className="what-in-box-mobile">
            <div className="item">
              <img src={mobileBox} alt="Phone & Case" />
              <p>Phone & Case</p>
            </div>
            <div className="item">
              <img src={charger} alt="Charger Cable" />
              <p>Charger Cable</p>
            </div>
          </div>
        </center>
      </MobileView>
    </div>
  );
}

const RightSide = ({
  changeColor,
  selectedColor,
  setSelectedKosherLevel,
  selectedKosherLevel,
  handleContinue,
  colorOptions,
  setHoveredColor,
  hoveredColor,
  visibilityState,
  selectedBuyOption,
  SelectedPlanPrice,
}) => {
  const kosherLevels = [
    "PURE +",
    "PURE",
    "Business",
    "Basic",
    "Pure Light",
    "Waze Only",
    "Zoom Only",
  ];
  const kosherLevelsDes = [
    "All Apps",
    "Fewer Entertainment Apps",
    "All Business related apps",
    "Basic Banking Apps",
    "Torah Apps only",
    "",
    "",
  ];

  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="right-section-mobile">
      <BrowserView>
        {" "}
        <div className="model-info-mobile">
          <h2 className="model-title-mobile">
            Model: <span className="info-icon"></span>
          </h2>
          <div className="model-selection">
            <div className="model-details">
              <div className="model-name">Nova</div>
              <div className="model-size">6.5 inch display</div>
            </div>
            <div>
              {" "}
              <div className="promo-animation">Special promo</div>
              <div
                className="model-price"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                From $9.99 or{" "}
                <span style={{ textDecoration: "line-through", color: "red" }}>
                  $350
                </span>{" "}
                $99.99
              </div>{" "}
            </div>
          </div>
        </div>
      </BrowserView>

      <BrowserView>
        <h2 className="choose-color-text">Choose your Color:</h2>

        <div className="color-selection-mobile-page">
          {colorOptions.map((option, index) => (
            <>
              <div
                style={{
                  textAlign: "center",
                  paddingTop: "15px", // Padding at the top
                  paddingLeft: "15px", // Padding at the top
                }}
              >
                <div
                  key={index}
                  className="color-option"
                  style={{
                    backgroundColor: option.code,
                    border:
                      selectedColor === option.name.toLowerCase()
                        ? "5px solid grey"
                        : "",
                  }}
                  onMouseEnter={() => setHoveredColor(option.name)}
                  onMouseLeave={() => setHoveredColor("")}
                  onClick={() => {
                    if (!option.soldOut) {
                      // Check if the option is not sold out before changing the color
                      changeColor(option.code);
                    } else {
                      window.alert("This color is Sold out.");
                    }
                  }}
                >
                  {hoveredColor === option.name && (
                    <div className="color-name-popup">{option.name}</div>
                  )}
                </div>
                <div
                  style={{
                    color: "red",
                    textAlign: "center",
                    paddingTop: "25px", // Padding at the top
                    fontSize: "12px", // Smaller text size
                  }}
                >
                  {" "}
                  {/* {option.soldOut && "Sold Out"} */}
                </div>
              </div>
            </>
          ))}
        </div>
      </BrowserView>

      <BrowserView>
        {selectedColor === "#dddddd" ? (
          <></>
        ) : (
          <div className="review-menu">
            <center>
              <h2>REVIEW ORDER</h2>
            </center>
            <div className="review-row">
              <span className="review-label">Color:</span>
              <span className="review-value">
                {selectedColor === "#dddddd" ? "" : selectedColor}
              </span>
            </div>

            {selectedBuyOption == "" ? (
              <></>
            ) : (
              <div className="review-row">
                <span className="review-label">Payment Plan:</span>
                <span className="review-value">
                  {selectedBuyOption === ""
                    ? ""
                    : selectedBuyOption === "option2"
                    ? "$9.99 per month for 12 months"
                    : selectedBuyOption === "option3"
                    ? "$9.99 per month for 12 months"
                    : "One time payment $99.99"}
                </span>
              </div>
            )}

            {SelectedPlanPrice == "" ? (
              <></>
            ) : (
              <div className="review-row">
                <span className="review-label">Data Plan:</span>
                <span className="review-value">
                  {SelectedPlanPrice === ""
                    ? ""
                    : SelectedPlanPrice === "$15"
                    ? "1 GB"
                    : SelectedPlanPrice === "$20"
                    ? "3 GB"
                    : SelectedPlanPrice === "$25"
                    ? "5 GB"
                    : SelectedPlanPrice === "$35"
                    ? "10 GB"
                    : "TMobile & At&t"}
                </span>
              </div>
            )}
          </div>
        )}
      </BrowserView>
      {/* <h2>Choose Kosher Level:</h2> */}

      {/* <div className="choose-kosher-level-mobile">
        {kosherLevels.map((level, index) => (
          <button
            style={{
              border: selectedKosherLevel === level ? "5px solid grey" : "",
            }}
            key={level}
            className={`kosher-level-button ${
              selectedKosherLevel === level ? "selected" : ""
            }`}
            onClick={() => setSelectedKosherLevel(level)}
          >
            {level}
            <span
              className={kosherLevelsDes[index] == "" ? "" : "tooltip-text"}
            >
              {kosherLevelsDes[index]}
            </span>
          </button>
        ))}
      </div> */}
      {visibilityState.planSelect && (
        <div
          className={visibilityState.planSelect ? "visible-options" : "hidden"}
        >
          {" "}
          <MobileView>
            {/* <div className="port-number-section-mobile">
              <h2>Port your existing number</h2>
              <button
                onClick={() =>
                  universalFunctions.portYourExistingNumberFunction()
                }
                className="port-button-mobile"
              >
                Port
              </button>
            </div> */}
            <div className="parent-continue-button">
              <button onClick={handleContinue} className="continue-button">
                Continue to buy
              </button>
            </div>
          </MobileView>
        </div>
      )}
    </div>
  );
};

const LeftSide = ({
  handleArrowClick,
  currentImage,
  selectedBuyOptions,
  selectedBuyOption,
  selectPrice,
  SelectedPlanPrice,
  handleContinue,
  isActive,
  changeColor,
  selectedColor,
  colorOptions,
  setHoveredColor,
  hoveredColor,
  visibilityState,
  maxsipOrOthers,
  selectMaxsipOrOther,
}) => {
  return (
    <div className="left-section">
      <div className="new-tag">New*</div>
      <h1 className="mobile-selection-container-title">Purchase PURE Phone</h1>
      <p className="price">From $9.99 only</p>
      <div className="product-image">
        <div className="image-container-mobile">
          <BrowserView>
            {" "}
            {/* <button
              onClick={() => handleArrowClick("left")}
              className="arrow-left-mobile"
            >
              &lt;
            </button> */}
          </BrowserView>

          <div className="phone-image-container">
            <MobileView>
              {(currentImage === imagesByColor.color1 ||
                currentImage === imagesByColor.color2 ||
                currentImage === imagesByColor.color3 ||
                currentImage === imagesByColor.color4 ||
                currentImage === imagesByColor.color5 ||
                currentImage === imagesByColor.color6) && (
                <div>
                  <img
                    style={{ height: "230px", width: "160px" }}
                    src={currentImage}
                    alt="Phone"
                    className={`phone-image-mobile ${
                      isActive ? "phone-image-active" : ""
                    }`}
                  />
                </div>
              )}
              <div className="color-selection-mobile-page">
                {colorOptions.map((option, index) => (
                  <>
                    <div
                      style={{
                        textAlign: "center",
                        paddingTop: "5px", // Padding at the top
                        paddingLeft: "5px", // Padding at the top
                      }}
                    >
                      <div
                        key={index}
                        className="color-option"
                        style={{
                          backgroundColor: option.code,
                          border:
                            selectedColor === option.name.toLowerCase()
                              ? "5px solid grey"
                              : "",
                        }}
                        onMouseEnter={() => setHoveredColor(option.name)}
                        onMouseLeave={() => setHoveredColor("")}
                        onClick={() => {
                          if (!option.soldOut) {
                            // Check if the option is not sold out before changing the color
                            changeColor(option.code);
                          } else {
                            window.alert("This color is Sold out.");
                          }
                        }}
                      >
                        {hoveredColor === option.name && (
                          <div className="color-name-popup">{option.name}</div>
                        )}
                      </div>
                      <div
                        style={{
                          color: "red",
                          textAlign: "center",
                          paddingTop: "20px", // Padding at the top
                          fontSize: "10px", // Smaller text size
                        }}
                      >
                        {" "}
                        {/* {option.soldOut && "Sold Out"} */}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </MobileView>

            <BrowserView>
              {" "}
              {(currentImage === imagesByColor.color1 ||
                currentImage === imagesByColor.color6) && (
                <div>
                  <img
                    style={{ height: "400px", width: "320px" }}
                    src={currentImage}
                    alt="Phone"
                    className={`phone-image-mobile ${
                      isActive ? "phone-image-active" : ""
                    }`}
                  />
                </div>
              )}
              {currentImage !== imagesByColor.color1 &&
                currentImage !== imagesByColor.color6 && (
                  <div>
                    <img
                      src={currentImage}
                      alt="Phone"
                      className={`phone-image-mobile ${
                        isActive ? "phone-image-active" : ""
                      }`}
                    />
                  </div>
                )}
            </BrowserView>
          </div>
          <BrowserView>
            {" "}
            {/* <button
              onClick={() => handleArrowClick("right")}
              className="arrow-right-mobile"
            >
              &gt;
            </button> */}
          </BrowserView>
        </div>
      </div>
      <MobileView>
        <MobileView>
          {" "}
          <div className="model-info-mobile">
            <h2 className="model-title-mobile">
              Model: <span className="info-icon"></span>
            </h2>
            <div className="model-selection">
              <div className="model-details">
                <div className="model-name">Nova</div>
                <div className="model-size">6.5 inch display</div>
              </div>
              <div>
                {" "}
                <div className="promo-animation">Special promo</div>
                <div
                  className="model-price"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  From $9.99 or{" "}
                  <span
                    style={{ textDecoration: "line-through", color: "red" }}
                  >
                    $350
                  </span>{" "}
                  $99.99
                </div>{" "}
              </div>
            </div>
          </div>
        </MobileView>
      </MobileView>
      {/* {visibilityState.color && ( */}
      <div className="visible-options">
        <div className="option-title">
          <h2>Payment options:</h2>
        </div>
        <div className="payment-options-mobile">
          <div
            className="payment-option"
            style={{
              border: selectedBuyOption === "Buy" ? "2px solid black" : "",
            }}
            onClick={() => selectedBuyOptions("Buy", "99.99")}
          >
            {" "}
            <h3>Buy</h3>
            <p className="payment-price">$99.99 only</p>
            <p>Pay with debit or credit card</p>
          </div>
          <div
            className="payment-option"
            style={{
              border: selectedBuyOption === "option2" ? "2px solid black" : "",
            }}
            onClick={() => selectedBuyOptions("option2", "9.99")}
          >
            <h3>1 year contract</h3>
            <p className="payment-price">$9.99 per month for 12 months</p>
            {/* <p>1 year contract</p> */}
            <p>Pay over time at 0% APR.</p>
          </div>
        </div>
      </div>

      {visibilityState.paymentOption && (
        <div
          className={
            visibilityState.paymentOption ? "visible-options" : "hidden"
          }
        >
          <div className="monthly-data-plan-mobile">
            <h2>Connectivity, Choose a carrier:</h2>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div
              className="data-plan-option-mobile"
              style={{
                border:
                  selectMaxsipOrOther === "maxsip" ? "2px solid black" : "",
                paddingLeft: "40px",
                paddingRight: "40px",
                marginTop: "20px",
                marginRight: "20px",
                flex: 1, // Makes each child div take equal space
                display: "flex", // Enables flexbox layout inside this div
                alignItems: "center", // Vertically centers the content inside
                justifyContent: "center", // Horizontally centers the content inside
                textAlign: "center", // Ensures text alignment is centered (for multi-line texts)
                height: "100px",
                fontSize: "20px",
              }}
              onClick={() => maxsipOrOthers("maxsip")}
            >
              <span className="data-amount">Maxsip Plan</span>
            </div>

            <div
              className="data-plan-option-mobile"
              style={{
                border:
                  selectMaxsipOrOther === "other" ? "2px solid black" : "",
                paddingLeft: "40px",
                paddingRight: "40px",
                marginTop: "20px",
                marginRight: "20px",
                flex: 1, // Makes each child div take equal space
                display: "flex", // Enables flexbox layout inside this div
                alignItems: "center", // Vertically centers the content inside
                justifyContent: "center", // Horizontally centers the content inside
                textAlign: "center", // Ensures text alignment is centered (for multi-line texts)
                height: "100px", // Set a specific height or adjust as needed to ensure vertical centering
                marginLeft: "20px",
              }}
              onClick={() => maxsipOrOthers("other")}
            >
              <span className="data-amount" style={{ fontSize: "20px" }}>
                Connect Existing Carrier
              </span>
            </div>
          </div>

          {visibilityState.maxsipPlan && (
            <div className="monthly-data-plan-mobile">
              <h2>Monthly Data Plan</h2>
              <div className="data-plan-options-mobile">
                <div
                  className="data-plan-option-mobile"
                  style={{
                    border:
                      SelectedPlanPrice === "$15" ? "2px solid black" : "",
                  }}
                  onClick={() => selectPrice("$15")}
                >
                  <span className="data-amount">1GB</span>
                  <span className="data-price">$15</span>
                </div>
                <div
                  className="data-plan-option-mobile"
                  style={{
                    border:
                      SelectedPlanPrice === "$20" ? "2px solid black" : "",
                  }}
                  onClick={() => selectPrice("$20")}
                >
                  <span className="data-amount">3GB</span>
                  <span className="data-price">$20</span>
                </div>
                <div
                  className="data-plan-option-mobile"
                  style={{
                    border:
                      SelectedPlanPrice === "$25" ? "2px solid black" : "",
                  }}
                  onClick={() => selectPrice("$25")}
                >
                  <span className="data-amount">5GB</span>
                  <span className="data-price">$25</span>
                </div>
                <div
                  className="data-plan-option-mobile"
                  style={{
                    border:
                      SelectedPlanPrice === "$35" ? "2px solid black" : "",
                  }}
                  onClick={() => selectPrice("$35")}
                >
                  <span className="data-amount">10GB</span>
                  <span className="data-price">$35</span>
                </div>
              </div>
            </div>
          )}
          {visibilityState.otherPlan && (
            <div
              className="data-plan-option-mobile"
              style={{
                border: SelectedPlanPrice === "$9.99" ? "2px solid black" : "",
                paddingLeft: "40px",
                paddingRight: "40px",

                marginTop: "20px",
              }}
              onClick={() => selectPrice("$9.99")}
            >
              <span className="data-price">
                <span
                  style={{
                    textDecoration: "line-through",
                    marginTop: "20px",
                    color: "red",
                  }}
                >
                  $15
                </span>
                <span style={{ color: "green" }}>{"  "}$9.99 </span>for Kosher
                <br></br>
                <span className="" style={{ fontSize: "15px" }}>
                  TMobile & At&t
                </span>
              </span>
            </div>
          )}
          {/* 
          
          */}
        </div>
      )}

      {visibilityState.planSelect && (
        <div
          className={visibilityState.planSelect ? "visible-options" : "hidden"}
        >
          {" "}
          <BrowserView className="port-number-section-mobile">
            {/* <div className="port-number-section-mobile">
              <h2>Port your existing number</h2>
              <button
                onClick={() =>
                  universalFunctions.portYourExistingNumberFunction()
                }
                className="port-button-mobile"
              >
                Port
              </button>
            </div> */}
            <button onClick={handleContinue} className="continue-button">
              Continue to buy
            </button>
          </BrowserView>
        </div>
      )}

      <BrowserView className="in-box-text-mobile">
        <center>
          <h5>What's in the box</h5>
        </center>
        <center>
          <div className="what-in-box-mobile">
            <div className="item">
              <img src={mobileBox} alt="Phone & Case" />
              <p>Phone & Case</p>
            </div>
            <div className="item">
              <img src={charger} alt="Charger Cable" />
              <p>Charger Cable</p>
            </div>
          </div>
        </center>
      </BrowserView>
    </div>
  );
};

export default NovaDeviceSelectionPage;

/*

*/
