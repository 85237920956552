import React, { useState } from "react";
import "./ChoosePlanPageMobileView.css";
import scrollUp from "../../../assets/scrollup.png";
import { useNavigate } from 'react-router-dom';


function ChoosePlanPageMobileView(props) {
  const navigate = useNavigate();

  const [activeSlide, setactiveSlide] = useState(props.activeSlide);
  const handleMoreInfoClick = (path) => {
    switch(path) {
      case "Pure +":
        navigate("/pure-plus");
        break;
      case "Pure":
        navigate("/pure");
        break;
      case "Business":
        navigate("/business");
        break;
      case "Basic":
        navigate("/basic");
        break;
      case "Pure Light":
        navigate("/light");
        break;
      default:
        console.log("Path not found");
    }
  };

  return (

    <div class="slider">
  <h2 className="kosherLevelText">Build Your Kosher App Store</h2>
    <div class="slides">
      <div id="slide-1">
      <div className="sliderContent-mobileView">
      <h2>Pure Light</h2>
      <p>Fewer Apps</p>
      <button onClick={() => handleMoreInfoClick("Pure Light")}>More Info</button>
    </div>
      </div>
      <div id="slide-2">
      <div className="sliderContent-mobileView">
      <h2>Business</h2>
      <p>500+ apps</p>
      <button onClick={() => handleMoreInfoClick("Business")}>More Info</button>
    </div>
      </div>
      <div id="slide-3">
      <div className="sliderContent-mobileView">
      <h2>Basic</h2>
      <p>General Apps</p>
      <button onClick={() => handleMoreInfoClick("Basic")}>More Info</button>
    </div>
      </div>
      <div id="slide-4">
      <div className="sliderContent-mobileView">
      <h2>Pure</h2>
      <p>Entertainment Apps</p>
      <button onClick={() => handleMoreInfoClick("Pure")}>More Info</button>
    </div>
      </div>
      <div id="slide-5">
      <div className="sliderContent-mobileView">
      <h2>Pure +</h2>
      <p>Over 500+ Apps</p>
      <button onClick={() => handleMoreInfoClick("Pure +")} >More Info</button>
    </div>
      </div>
    </div>
  </div>
    
  );
}



export default ChoosePlanPageMobileView;