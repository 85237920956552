import Swal from 'sweetalert2';

function calculatePercentage(value) {
    const percentageRate = 8.875 / 100;
    
    // Check if the value is a string that can be converted to a number
    if (typeof value === 'string' && !isNaN(value)) {
      // Convert string to number, calculate percentage, and return as string
      const numericValue = parseFloat(value);
      const result = numericValue * percentageRate;
      return result.toFixed(2).toString(); // toFixed(2) limits the result to 2 decimal places
    } else if (typeof value === 'number') {
      // Calculate percentage and return as number
      return value * percentageRate;
    } else {
      // Return null or throw an error if the input is not valid
      console.error('Input must be a number or a numeric string');
      return null;
    }
  }
  
  function portYourExistingNumberFunction() {
    

    Swal.fire({
      title: 'Keep Your Phone Number When You Change Providers.',
      text: 'Contact your current provider for your account and Pin Number. Login to your account to Activate and Port in your number',
      icon: 'info',
      confirmButtonText: 'OK'
  });
  }
  
 

  function isLocalStorageAvailable() {
    try {
      const test = "__test__";
      window.localStorage.setItem(test, test);
      window.localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }






  const saveOrderDataToLocalStorage = (orderData) => {
    // Check if order data already exists in localStorage
    const existingOrderData = localStorage.getItem("orderData");
  
    if (!existingOrderData) {
      // If no data is found, store a string indicating "No app selected"
      localStorage.setItem("orderData", JSON.stringify({ apps: "No app selected" }));
    } else {
      // If data exists, store the new order data
      localStorage.setItem("orderData", JSON.stringify(orderData));
    }
  };
  

  const getOrderDataFromLocalStorage = () => {
    try {
      const orderData = localStorage.getItem("orderData");
      if (orderData) {
        return JSON.parse(orderData);
      } else {
        console.log("No order data found in localStorage");
        return null;
      }
    } catch (error) {
      console.error("Error retrieving order data from localStorage:", error);
      return null;
    }
  };
  
  const removeOrderDataFromLocalStorage = () => {
    try {
      localStorage.removeItem("orderData");
      console.log("Order data removed from localStorage");
    } catch (error) {
      console.error("Error removing order data from localStorage:", error);
    }
  };
  
  function validateState(state) {
    // List of valid US state abbreviations (both upper and lower case)
    const validStates = [
      "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID",
      "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS",
      "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK",
      "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV",
      "WI", "WY"
    ];
  
    // Check if state is provided
    if (!state) {
      console.log("State is not provided");
      return false;
    }
  
    // Trim any extra spaces and convert state to uppercase
    const trimmedState = state.trim().toUpperCase();
  
    // Debugging output: check what the trimmed state looks like
    console.log("Trimmed and uppercased state: ", trimmedState);
  
    // Ensure the state input is exactly two letters
    if (trimmedState.length !== 2) {
      console.log("State is not exactly two letters");
      return false;
    }
  
    // Check if the trimmed state is in the list of valid states
    if (validStates.includes(trimmedState)) {
      return true;
    } else {
      console.log("State is not in the valid list");
      return false;
    }
  }
  
  export default {
    calculatePercentage,
    portYourExistingNumberFunction,
    isLocalStorageAvailable,
    saveOrderDataToLocalStorage,
    getOrderDataFromLocalStorage,
    removeOrderDataFromLocalStorage,
    validateState
  
  };

